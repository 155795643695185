import {   DownloadOutlined, FileExcelOutlined, FolderOpenFilled,} from '@ant-design/icons';
import { Col, Menu, Row, Select, Spin, Table, TableColumnsType, } from 'antd';
import Modal from 'antd/es/modal/Modal';
import * as React from 'react';
import { getUserProfile } from '../../authen/UserProfile';
import ProjectActionModel from '../../_constant/Project/ProjectActionModel';
import UploadBlobForm from './UploadBlobForm';
import { format } from 'date-fns';
const dateFormat = 'dd-MMM-yyyy HH:mm:ss';


class UploadBlob extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visibleUploadBlobForm: false,
            uploadList: [],
            uploadListTable: [],
            searchProject: [],
            rowProjectSelection: [],
            deleteButtonVisible: false,
            pagination: {},
            pageSize: 10,
            page: 1,
            folderName: "",
            project: {},
            sheets: [],
            userProfile: null,
            spinning: false,
            percent: 0,
            cur_path:"",
        }
    }

    //handleSubmit = (e: any) => {
    //    console.log('Submitting project:', { e });
    //};


    componentWillMount = async () => {
        window.scrollTo(0, 0)

        let userProfile: any = await getUserProfile();
        await this.setState({ userProfile })
        let { folder } = await this.props.match.params;
        let { path } = await this.props.match;
        await this.setState({ folderName: folder })
        await this.GetProjectsDataByProjectName(folder)
        await this.checkUploadStatus()


    }


    GetUploadListData = async (e: any) => {
        //await console.log(e)
        const { pageSize, userProfile } = await this.state;
        const value: any = await {
            "offset": JSON.stringify(e == null ? 0 : ((e.page - 1) * e.pageSize)),
            "limit": JSON.stringify(e == null ? pageSize : e.pageSize),
            "sort": "ProjectID",

            "creator": userProfile.mail,
            "projectID": JSON.stringify(e == null ? 0 : e.ProjectID),

        };
        await ProjectActionModel.getUploadList(value).then((res: any) => {
            let uploadList: any = [];
            let pagination = {};

            try {
                res.data == null ? [] : res.data
                    .map((d: any) => uploadList.push({

                        key: d.uploadID,
                        UploadID: d.uploadID,
                        SheetID: d.sheetID,
                        ProjectID: d.projectID,
                        FileUploadName: d.fileUploadName,
                        FileUploadSheetName: d.fileUploadSheetName,

                    }));
                pagination = res.pagination

            } catch {
                uploadList = [];
            }
            this.setState({ pagination, uploadList, uploadListTable: uploadList, })

        })

    }
    //GetsheetsListData = async (e: any) => {
    //    //await console.log(e)
    //    const { pageSize } = await this.state;
    //    const value: any = await {
    //        "offset": '0',
    //        "limit": '10000',
    //        "sort": "SheetID",
    //        "projectID": JSON.stringify(e == null ? 0 : e.ProjectID),
    //    };
    //    let sheets: any = [];
    //    await ProjectActionModel.getSheetList(value).then((res: any) => {

    //        res.data == null ? [] : res.data
    //            .map((d: any) => sheets.push({
    //                key: d.sheetID,
    //                SheetID: d.sheetID,
    //                ProjectID: d.projectID,
    //                SheetName: d.sheetName,
    //                IngestMode: d.ingestMode,
    //                value: d.sheetID,
    //                label: d.sheetName,
    //            }));

    //        this.setState({ sheets })

    //    })


    //}



    GetProjectById = async (e: any) => {
        //await console.log(e)
        try {
            const value: any = await {
                "projectID": JSON.stringify(e == null ? 0 : e.ProjectID),
            };
            let sheets: any = [];
            let uploadList: any = [];
            let project: any = [];

            await ProjectActionModel.getProjectById(value).then((res: any) => {
                //console.log(res)

                res.sheets == null ? [] : res.sheets
                    .map((d: any) => {
                        if (d.isActive) {
                            sheets.push({
                                key: d.sheetID,
                                SheetID: d.sheetID,
                                ProjectID: d.projectID,
                                SheetName: d.sheetName,
                                IngestMode: d.ingestMode,
                                DownloadTemplateUri: d.downloadTemplateUri,
                                IsActive: d.isActive,

                                value: d.sheetID,
                                label: d.sheetName,
                            })
                        }
                    });

                    res.uploads == null ? [] : res.uploads
                        .map((d: any) => uploadList.push({

                            key: d.uploadID,
                            UploadID: d.uploadID,
                            SheetID: d.sheetID,
                            ProjectID: d.projectID,
                            //OriginalFileName: d.originalFileName,
                            FileUploadName: d.originalFileName,
                            FileUploadSheetName: d.fileUploadSheetName,
                            ResultStatus: d.resultStatus,
                            ResultMessage: d.resultMessage,
                            UpdateDate: d.updateDate,
                            CreateDate: d.createDate

                        }));
                //console.log(res.uploads)
                    this.setState({ sheets, uploadList, project: res })
            })
        } catch {
            this.setState({ sheets: [], uploadList: [], project: [] })

        }
        window.scrollTo(0, 0)

    }


    GetProjectsDataByProjectName = async (projectID: any) => {

        try {
            const value: any = await {
                "offset": "0",
                "limit": "10000",
                "sort": "ProjectID",

                //"projectName": projectName,
                "projectName": projectID,
                "ProjectID": JSON.parse(projectID)
            };
            this.setState({ spinning: true })
            //Data Test and Defualt
            if (projectID === 'chem-datauploadcenter') {
                let project: any = {};
                this.setState({
                    project: {
                        key: 0,
                        ProjectID: 0,
                        ProjectName: 'chem-datauploadcenter',
                        CompanyCode: '0090',
                        CompanyName: 'chem',
                        LandingPath: 'chem-datauploadcenter',
                    }
                })
                this.setState({ project })


            } else {
                this.GetProjectById(value).then(() => { this.setState({ spinning: false, visibleUploadBlobForm: false }) })
            }
        } catch {

            this.GetProjectById("").then(() => { this.setState({ spinning: false }) })

        }
    }

    rowSelection = (e: any) => {
        console.log(e)
    }


    onChangeSearch = (e: any) => {
        //console.log(e)

        const { projectList } = this.state;


        let searchProject = e;
        try {

            //this.setState({ searchProject })
            let projectListTable: any = [];

            if (searchProject.length > 0) {

                searchProject.map((a: any) => {
                    projectList.filter((b: any) => JSON.stringify(b).toLowerCase().trim().includes(a.toLowerCase().trim()))
                        .map((c: any) =>
                            projectListTable.push(c)
                        )
                })

                //return projectList.filter((a: any) => JSON.stringify(a).includes(...searchProject))

            } else {
                projectListTable = projectList;
            }



            this.setState({ projectListTable })


        }
        catch {

        }

    }

    onClickButtonBar = async (e: any) => {
        //switch (e.key) {
        //    case 'upload':
        //        this.setState({ visibleUploadBlobForm: true })
        //        break;
        //    case 'download':
        //        console.log(e)
        //        break;
        //}

        //switch (e.keyPath[1]) {
        //    case 'upload':
        //        this.setState({ visibleUploadBlobForm: true })
        //        break;
        //    case 'download':
        //        console.log(e)
        //        break;
        //}
        //console.log(e)

        if (e.key === 'upload') {
          await  this.setState({ visibleUploadBlobForm: true })
        } else if (e.keyPath[1] == 'download') {
            window.location.href = e.key
        }
    }



    componentWillReceiveProps = async (props: any) => {
        let { folder } = this.props.match.params;
        //console.log(folder,newfolder)
        if (props.match.params.folder !== folder) {
            await this.setState({ folderName: props.match.params.folder })
            await this.GetProjectsDataByProjectName(props.match.params.folder);
            //await this.checkUploadStatus()
        }
    }




    checkUploadStatus = () => {
        //const { uploadList } = await this.state;
        //await console.log(uploadList)
        //const { uploadList } = await this.state;
        //const chk_uploadlikt = uploadList.filter((d: any) => d.ResultStatus === "Uploading")
        //let count = 0;
        //let { path } = this.props.match;
        //const intervalId: any = setInterval((e: any) => {
        //    const { uploadList } = this.state;
        //    let { folder } = this.props.match.params;
        //    console.log(intervalId);

        //    count++
        //    console.log(path, count)
        //    const chk_uploadlikt = uploadList.filter((d: any) => d.ResultStatus === "Uploading")
        //    const value: any = {
        //        "ProjectID": JSON.parse(folder)
        //};
        //    if (chk_uploadlikt !== [] && count<=15) {
        //        this.GetProjectById(value);
        //    } else {
        //        console.log(intervalId);
        //    }
        //}

        //, 30000)

    }

    public render() {

        const { visibleUploadBlobForm,
            uploadList,
            folderName,
            project,
            sheets,
            spinning,
            //pageSize,
        } = this.state;

        //const handleChangePage = (page: any, pageSize: any) => {
        //    this.setState({ pageSize, page })
        //    /*this.GetUploadListData({ page, pageSize, ProjectID: project.ProjectID })*/

        //    //uploadList

        //};
        const columnsUpload: TableColumnsType<any> = [
            {
                title: 'ID',
                dataIndex: 'UploadID',
                width: '90px',
            },
            {
                title: 'Topic',
                dataIndex: 'SheetID',
            },
            {
                title: 'File Name',
                dataIndex: 'FileUploadName',
            },
            {
                title: 'Sheet name in file',
                dataIndex: 'FileUploadSheetName',
            },
            {
                title: 'Upload Date',
                dataIndex: 'CreateDate',
                render: (CreateDate: any) => format(CreateDate, dateFormat),
            },
            {
                title: 'Result',
                dataIndex: 'ResultStatus',
            },
            {
                title: 'Result Description',
                dataIndex: 'ResultMessage',
            },
        ];

        const menuTemplate: any = [];

        sheets === [] || sheets === null ? [] : sheets.map((d: any,i:any) => menuTemplate.push({
            label: d.SheetName,
            key: d.DownloadTemplateUri+'#'+i,
           // value: d.DownloadTemplateUri
        }))

        const items: any = [
            {
                label: 'Upload',
                key: 'upload',
                icon: <FileExcelOutlined style={{ color: "#1D6F42" }} />,
            },
            {
                label: 'Download template',
                key: 'download',
                icon: <DownloadOutlined style={{ color: "#1D6F42" }} />,
                children: menuTemplate
            },

        ];




        //const rowSelection = {
        //    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
        //        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //        selectedRows !== null && selectedRows.length == 0 ? this.setState({ rowProjectSelection: [], deleteButtonVisible: false }) : this.setState({ rowProjectSelection: selectedRows, deleteButtonVisible: true });
        //        console.log(selectedRows !== null, selectedRows.length == 0);

        //    },
        //    getCheckboxProps: (record: any) => ({
        //        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //        name: record.name,
        //    }),
        //};




        return (<>
            <Spin spinning={spinning} fullscreen />
            <Row style={{ padding: "10px" }}>
                <h4><FolderOpenFilled style={{ color: "#FFD700", paddingRight: "10px" }} />{project.projectName}</h4>
                <Col
                    offset={1}
                    key="Search"
                    span={24} >
                    <Menu
                        selectedKeys={["0"]}
                        mode="horizontal"
                        items={items}
                        onClick={(e: any) => this.onClickButtonBar(e)}
                        defaultChecked
                    />
                </Col>
            </Row>
            <Row style={{ padding: "5px" }}>

                <Col
                    offset={1}
                    key="Search"
                    span={16} >
                    <Row>
                        <Col span={4}>
                            <h6> Upload history:</h6>
                        </Col>

                        <Col span={12}>
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Search"
                                showSearch
                                onChange={(e: any) => this.onChangeSearch(e)}
                                size={"small"}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ padding: "5px" }}>
                <Col
                    offset={1}
                    key="Table"
                    span={23}
                >
                    <Table

                        columns={columnsUpload}
                        dataSource={uploadList}
                        size="small"
                        pagination={{ pageSize: 10, pageSizeOptions: [10, 50, 100] }}
                    // sortDirections={[]}
                    />
                    {
                        //<Pagination
                        //    size="small"
                        //    total={uploadList.length}
                        //    pageSizeOptions={[10, 50, 100]}
                        //    onChange={handleChangePage}
                        //    pageSize={pageSize}
                        //    style={{ float: "right" }}
                        ///>
                    }
                </Col>
            </Row>

            {/* Form Area */}
            <Modal
                visible={visibleUploadBlobForm}
                footer={false}
                closable={false}
              //  onCancel={() => this.setState({ visibleUploadBlobForm: false })}
                maskClosable={false}
                width={'800px'}
            >
                <UploadBlobForm
                    title={folderName}
                    project={project}
                    sheets={sheets}
                    reloadTable={this.GetProjectsDataByProjectName}
                    closeForm = {() => this.setState({ visibleUploadBlobForm: false })}
                />
            </Modal>
        </>
        );
    }
}

export default (UploadBlob);