import * as React from 'react';
import {
    StepBackwardOutlined,
    StepForwardOutlined,
    FastBackwardOutlined,
    FastForwardOutlined,
    ShrinkOutlined,
    ArrowsAltOutlined,
    DownOutlined,
    UpOutlined,
    LeftOutlined,
    RightOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined,
    VerticalAlignTopOutlined,
    VerticalAlignMiddleOutlined,
    VerticalAlignBottomOutlined,
    ForwardOutlined,
    BackwardOutlined,
    RollbackOutlined,
    EnterOutlined,
    RetweetOutlined,
    SwapOutlined,
    SwapLeftOutlined,
    SwapRightOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    PlayCircleOutlined,
    UpSquareOutlined,
    DownSquareOutlined,
    LeftSquareOutlined,
    RightSquareOutlined,
    LoginOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    BorderBottomOutlined,
    BorderHorizontalOutlined,
    BorderInnerOutlined,
    BorderOuterOutlined,
    BorderLeftOutlined,
    BorderRightOutlined,
    BorderTopOutlined,
    BorderVerticleOutlined,
    PicCenterOutlined,
    PicLeftOutlined,
    PicRightOutlined,
    RadiusBottomleftOutlined,
    RadiusBottomrightOutlined,
    RadiusUpleftOutlined,
    RadiusUprightOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    QuestionOutlined,
    QuestionCircleOutlined,
    PlusOutlined,
    PlusCircleOutlined,
    PauseOutlined,
    PauseCircleOutlined,
    MinusOutlined,
    MinusCircleOutlined,
    PlusSquareOutlined,
    MinusSquareOutlined,
    InfoOutlined,
    InfoCircleOutlined,
    ExclamationOutlined,
    ExclamationCircleOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    CloseSquareOutlined,
    CheckOutlined,
    CheckCircleOutlined,
    CheckSquareOutlined,
    ClockCircleOutlined,
    WarningOutlined,
    IssuesCloseOutlined,
    StopOutlined,
    EditOutlined,
    FormOutlined,
    CopyOutlined,
    ScissorOutlined,
    DeleteOutlined,
    SnippetsOutlined,
    DiffOutlined,
    HighlightOutlined,
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    BgColorsOutlined,
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    StrikethroughOutlined,
    RedoOutlined,
    UndoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    FontColorsOutlined,
    FontSizeOutlined,
    LineHeightOutlined,
    DashOutlined,
    SmallDashOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined,
    DragOutlined,
    OrderedListOutlined,
    UnorderedListOutlined,
    RadiusSettingOutlined,
    ColumnWidthOutlined,
    ColumnHeightOutlined,
    AreaChartOutlined,
    PieChartOutlined,
    BarChartOutlined,
    DotChartOutlined,
    LineChartOutlined,
    RadarChartOutlined,
    HeatMapOutlined,
    FallOutlined,
    RiseOutlined,
    StockOutlined,
    BoxPlotOutlined,
    FundOutlined,
    SlidersOutlined,
    AppleOutlined,
    WindowsOutlined,
    IeOutlined,
    ChromeOutlined,
    GithubOutlined,
    AliwangwangOutlined,
    DingdingOutlined,
    WeiboSquareOutlined,
    WeiboCircleOutlined,
    TaobaoCircleOutlined,
    Html5Outlined,
    WeiboOutlined,
    TwitterOutlined,
    WechatOutlined,
    WhatsAppOutlined,
    YoutubeOutlined,
    AlipayCircleOutlined,
    TaobaoOutlined,
    DingtalkOutlined,
    SkypeOutlined,
    QqOutlined,
    MediumWorkmarkOutlined,
    GitlabOutlined,
    MediumOutlined,
    LinkedinOutlined,
    GooglePlusOutlined,
    DropboxOutlined,
    FacebookOutlined,
    CodepenOutlined,
    CodeSandboxOutlined,
    AmazonOutlined,
    GoogleOutlined,
    CodepenCircleOutlined,
    AlipayOutlined,
    AntDesignOutlined,
    AntCloudOutlined,
    AliyunOutlined,
    ZhihuOutlined,
    SlackOutlined,
    SlackSquareOutlined,
    BehanceOutlined,
    BehanceSquareOutlined,
    DribbbleOutlined,
    DribbbleSquareOutlined,
    InstagramOutlined,
    YuqueOutlined,
    AlibabaOutlined,
    YahooOutlined,
    RedditOutlined,
    SketchOutlined,
    WechatWorkOutlined,
    OpenAIOutlined,
    DiscordOutlined,
    XOutlined,
    BilibiliOutlined,
    PinterestOutlined,
    TikTokOutlined,
    SpotifyOutlined,
    TwitchOutlined,
    LinuxOutlined,
    JavaOutlined,
    JavaScriptOutlined,
    PythonOutlined,
    RubyOutlined,
    DotNetOutlined,
    KubernetesOutlined,
    DockerOutlined,
    BaiduOutlined,
    HarmonyOSOutlined,
    AimOutlined,
    AlertOutlined,
    ApartmentOutlined,
    ApiOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined,
    AudioOutlined,
    AudioMutedOutlined,
    AuditOutlined,
    BankOutlined,
    BarcodeOutlined,
    BarsOutlined,
    BellOutlined,
    BlockOutlined,
    BookOutlined,
    BorderOutlined,
    BorderlessTableOutlined,
    BranchesOutlined,
    BugOutlined,
    BuildOutlined,
    BulbOutlined,
    CalculatorOutlined,
    CalendarOutlined,
    CameraOutlined,
    CarOutlined,
    CarryOutOutlined,
    CiCircleOutlined,
    CiOutlined,
    ClearOutlined,
    CloudDownloadOutlined,
    CloudOutlined,
    CloudServerOutlined,
    CloudSyncOutlined,
    CloudUploadOutlined,
    ClusterOutlined,
    CodeOutlined,
    CoffeeOutlined,
    CommentOutlined,
    CompassOutlined,
    CompressOutlined,
    ConsoleSqlOutlined,
    ContactsOutlined,
    ContainerOutlined,
    ControlOutlined,
    CopyrightOutlined,
    CreditCardOutlined,
    CrownOutlined,
    CustomerServiceOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    DeleteColumnOutlined,
    DeleteRowOutlined,
    DeliveredProcedureOutlined,
    DeploymentUnitOutlined,
    DesktopOutlined,
    DisconnectOutlined,
    DislikeOutlined,
    DollarOutlined,
    DownloadOutlined,
    EllipsisOutlined,
    EnvironmentOutlined,
    EuroCircleOutlined,
    EuroOutlined,
    ExceptionOutlined,
    ExpandAltOutlined,
    ExpandOutlined,
    ExperimentOutlined,
    ExportOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    FieldBinaryOutlined,
    FieldNumberOutlined,
    FieldStringOutlined,
    FieldTimeOutlined,
    FileAddOutlined,
    FileDoneOutlined,
    FileExcelOutlined,
    FileExclamationOutlined,
    FileOutlined,
    FileGifOutlined,
    FileImageOutlined,
    FileJpgOutlined,
    FileMarkdownOutlined,
    FilePdfOutlined,
    FilePptOutlined,
    FileProtectOutlined,
    FileSearchOutlined,
    FileSyncOutlined,
    FileTextOutlined,
    FileUnknownOutlined,
    FileWordOutlined,
    FileZipOutlined,
    FilterOutlined,
    FireOutlined,
    FlagOutlined,
    FolderAddOutlined,
    FolderOutlined,
    FolderOpenOutlined,
    FolderViewOutlined,
    ForkOutlined,
    FormatPainterOutlined,
    FrownOutlined,
    FunctionOutlined,
    FundProjectionScreenOutlined,
    FundViewOutlined,
    FunnelPlotOutlined,
    GatewayOutlined,
    GifOutlined,
    GiftOutlined,
    GlobalOutlined,
    GoldOutlined,
    GroupOutlined,
    HddOutlined,
    HeartOutlined,
    HistoryOutlined,
    HolderOutlined,
    HomeOutlined,
    HourglassOutlined,
    IdcardOutlined,
    ImportOutlined,
    InboxOutlined,
    InsertRowAboveOutlined,
    InsertRowBelowOutlined,
    InsertRowLeftOutlined,
    InsertRowRightOutlined,
    InsuranceOutlined,
    InteractionOutlined,
    KeyOutlined,
    LaptopOutlined,
    LayoutOutlined,
    LikeOutlined,
    LineOutlined,
    LinkOutlined,
    Loading3QuartersOutlined,
    LoadingOutlined,
    LockOutlined,
    MacCommandOutlined,
    MailOutlined,
    ManOutlined,
    MedicineBoxOutlined,
    MehOutlined,
    MenuOutlined,
    MergeCellsOutlined,
    MergeOutlined,
    MessageOutlined,
    MobileOutlined,
    MoneyCollectOutlined,
    MonitorOutlined,
    MoonOutlined,
    MoreOutlined,
    MutedOutlined,
    NodeCollapseOutlined,
    NodeExpandOutlined,
    NodeIndexOutlined,
    NotificationOutlined,
    NumberOutlined,
    OneToOneOutlined,
    PaperClipOutlined,
    PartitionOutlined,
    PayCircleOutlined,
    PercentageOutlined,
    PhoneOutlined,
    PictureOutlined,
    PlaySquareOutlined,
    PoundCircleOutlined,
    PoundOutlined,
    PoweroffOutlined,
    PrinterOutlined,
    ProductOutlined,
    ProfileOutlined,
    ProjectOutlined,
    PropertySafetyOutlined,
    PullRequestOutlined,
    PushpinOutlined,
    QrcodeOutlined,
    ReadOutlined,
    ReconciliationOutlined,
    RedEnvelopeOutlined,
    ReloadOutlined,
    RestOutlined,
    RobotOutlined,
    RocketOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SafetyCertificateOutlined,
    SafetyOutlined,
    SaveOutlined,
    ScanOutlined,
    ScheduleOutlined,
    SearchOutlined,
    SecurityScanOutlined,
    SelectOutlined,
    SendOutlined,
    SettingOutlined,
    ShakeOutlined,
    ShareAltOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    SignatureOutlined,
    SisternodeOutlined,
    SkinOutlined,
    SmileOutlined,
    SolutionOutlined,
    SoundOutlined,
    SplitCellsOutlined,
    StarOutlined,
    SubnodeOutlined,
    SunOutlined,
    SwitcherOutlined,
    SyncOutlined,
    TableOutlined,
    TabletOutlined,
    TagOutlined,
    TagsOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    ToTopOutlined,
    ToolOutlined,
    TrademarkCircleOutlined,
    TrademarkOutlined,
    TransactionOutlined,
    TranslationOutlined,
    TrophyOutlined,
    TruckOutlined,
    UngroupOutlined,
    UnlockOutlined,
    UploadOutlined,
    UsbOutlined,
    UserAddOutlined,
    UserDeleteOutlined,
    UserOutlined,
    UserSwitchOutlined,
    UsergroupAddOutlined,
    UsergroupDeleteOutlined,
    VerifiedOutlined,
    VideoCameraAddOutlined,
    VideoCameraOutlined,
    WalletOutlined,
    WifiOutlined,
    WomanOutlined,

} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
type MenuItem = Required<MenuProps>['items'][number];


function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}


export default class MainMenu extends React.Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

     IconProvider = (name: any) => {
         let _name = name==null?"": name.trim().toLowerCase()
        switch (_name) {
            case 'stepbackwardoutlined': return <StepBackwardOutlined />;
            case 'stepforwardoutlined': return <StepForwardOutlined />;
            case 'fastbackwardoutlined': return <FastBackwardOutlined />;
            case 'fastforwardoutlined': return <FastForwardOutlined />;
            case 'shrinkoutlined': return <ShrinkOutlined />;
            case 'arrowsaltoutlined': return <ArrowsAltOutlined />;
            case 'downoutlined': return <DownOutlined />;
            case 'upoutlined': return <UpOutlined />;
            case 'leftoutlined': return <LeftOutlined />;
            case 'rightoutlined': return <RightOutlined />;
            case 'caretupoutlined': return <CaretUpOutlined />;
            case 'caretdownoutlined': return <CaretDownOutlined />;
            case 'caretleftoutlined': return <CaretLeftOutlined />;
            case 'caretrightoutlined': return <CaretRightOutlined />;
            case 'upcircleoutlined': return <UpCircleOutlined />;
            case 'downcircleoutlined': return <DownCircleOutlined />;
            case 'leftcircleoutlined': return <LeftCircleOutlined />;
            case 'rightcircleoutlined': return <RightCircleOutlined />;
            case 'doublerightoutlined': return <DoubleRightOutlined />;
            case 'doubleleftoutlined': return <DoubleLeftOutlined />;
            case 'verticalleftoutlined': return <VerticalLeftOutlined />;
            case 'verticalrightoutlined': return <VerticalRightOutlined />;
            case 'verticalaligntopoutlined': return <VerticalAlignTopOutlined />;
            case 'verticalalignmiddleoutlined': return <VerticalAlignMiddleOutlined />;
            case 'verticalalignbottomoutlined': return <VerticalAlignBottomOutlined />;
            case 'forwardoutlined': return <ForwardOutlined />;
            case 'backwardoutlined': return <BackwardOutlined />;
            case 'rollbackoutlined': return <RollbackOutlined />;
            case 'enteroutlined': return <EnterOutlined />;
            case 'retweetoutlined': return <RetweetOutlined />;
            case 'swapoutlined': return <SwapOutlined />;
            case 'swapleftoutlined': return <SwapLeftOutlined />;
            case 'swaprightoutlined': return <SwapRightOutlined />;
            case 'arrowupoutlined': return <ArrowUpOutlined />;
            case 'arrowdownoutlined': return <ArrowDownOutlined />;
            case 'arrowleftoutlined': return <ArrowLeftOutlined />;
            case 'arrowrightoutlined': return <ArrowRightOutlined />;
            case 'playcircleoutlined': return <PlayCircleOutlined />;
            case 'upsquareoutlined': return <UpSquareOutlined />;
            case 'downsquareoutlined': return <DownSquareOutlined />;
            case 'leftsquareoutlined': return <LeftSquareOutlined />;
            case 'rightsquareoutlined': return <RightSquareOutlined />;
            case 'loginoutlined': return <LoginOutlined />;
            case 'logoutoutlined': return <LogoutOutlined />;
            case 'menufoldoutlined': return <MenuFoldOutlined />;
            case 'menuunfoldoutlined': return <MenuUnfoldOutlined />;
            case 'borderbottomoutlined': return <BorderBottomOutlined />;
            case 'borderhorizontaloutlined': return <BorderHorizontalOutlined />;
            case 'borderinneroutlined': return <BorderInnerOutlined />;
            case 'borderouteroutlined': return <BorderOuterOutlined />;
            case 'borderleftoutlined': return <BorderLeftOutlined />;
            case 'borderrightoutlined': return <BorderRightOutlined />;
            case 'bordertopoutlined': return <BorderTopOutlined />;
            case 'borderverticleoutlined': return <BorderVerticleOutlined />;
            case 'piccenteroutlined': return <PicCenterOutlined />;
            case 'picleftoutlined': return <PicLeftOutlined />;
            case 'picrightoutlined': return <PicRightOutlined />;
            case 'radiusbottomleftoutlined': return <RadiusBottomleftOutlined />;
            case 'radiusbottomrightoutlined': return <RadiusBottomrightOutlined />;
            case 'radiusupleftoutlined': return <RadiusUpleftOutlined />;
            case 'radiusuprightoutlined': return <RadiusUprightOutlined />;
            case 'fullscreenoutlined': return <FullscreenOutlined />;
            case 'fullscreenexitoutlined': return <FullscreenExitOutlined />;
            case 'questionoutlined': return <QuestionOutlined />;
            case 'questioncircleoutlined': return <QuestionCircleOutlined />;
            case 'plusoutlined': return <PlusOutlined />;
            case 'pluscircleoutlined': return <PlusCircleOutlined />;
            case 'pauseoutlined': return <PauseOutlined />;
            case 'pausecircleoutlined': return <PauseCircleOutlined />;
            case 'minusoutlined': return <MinusOutlined />;
            case 'minuscircleoutlined': return <MinusCircleOutlined />;
            case 'plussquareoutlined': return <PlusSquareOutlined />;
            case 'minussquareoutlined': return <MinusSquareOutlined />;
            case 'infooutlined': return <InfoOutlined />;
            case 'infocircleoutlined': return <InfoCircleOutlined />;
            case 'exclamationoutlined': return <ExclamationOutlined />;
            case 'exclamationcircleoutlined': return <ExclamationCircleOutlined />;
            case 'closeoutlined': return <CloseOutlined />;
            case 'closecircleoutlined': return <CloseCircleOutlined />;
            case 'closesquareoutlined': return <CloseSquareOutlined />;
            case 'checkoutlined': return <CheckOutlined />;
            case 'checkcircleoutlined': return <CheckCircleOutlined />;
            case 'checksquareoutlined': return <CheckSquareOutlined />;
            case 'clockcircleoutlined': return <ClockCircleOutlined />;
            case 'warningoutlined': return <WarningOutlined />;
            case 'issuescloseoutlined': return <IssuesCloseOutlined />;
            case 'stopoutlined': return <StopOutlined />;
            case 'editoutlined': return <EditOutlined />;
            case 'formoutlined': return <FormOutlined />;
            case 'copyoutlined': return <CopyOutlined />;
            case 'scissoroutlined': return <ScissorOutlined />;
            case 'deleteoutlined': return <DeleteOutlined />;
            case 'snippetsoutlined': return <SnippetsOutlined />;
            case 'diffoutlined': return <DiffOutlined />;
            case 'highlightoutlined': return <HighlightOutlined />;
            case 'aligncenteroutlined': return <AlignCenterOutlined />;
            case 'alignleftoutlined': return <AlignLeftOutlined />;
            case 'alignrightoutlined': return <AlignRightOutlined />;
            case 'bgcolorsoutlined': return <BgColorsOutlined />;
            case 'boldoutlined': return <BoldOutlined />;
            case 'italicoutlined': return <ItalicOutlined />;
            case 'underlineoutlined': return <UnderlineOutlined />;
            case 'strikethroughoutlined': return <StrikethroughOutlined />;
            case 'redooutlined': return <RedoOutlined />;
            case 'undooutlined': return <UndoOutlined />;
            case 'zoominoutlined': return <ZoomInOutlined />;
            case 'zoomoutoutlined': return <ZoomOutOutlined />;
            case 'fontcolorsoutlined': return <FontColorsOutlined />;
            case 'fontsizeoutlined': return <FontSizeOutlined />;
            case 'lineheightoutlined': return <LineHeightOutlined />;
            case 'dashoutlined': return <DashOutlined />;
            case 'smalldashoutlined': return <SmallDashOutlined />;
            case 'sortascendingoutlined': return <SortAscendingOutlined />;
            case 'sortdescendingoutlined': return <SortDescendingOutlined />;
            case 'dragoutlined': return <DragOutlined />;
            case 'orderedlistoutlined': return <OrderedListOutlined />;
            case 'unorderedlistoutlined': return <UnorderedListOutlined />;
            case 'radiussettingoutlined': return <RadiusSettingOutlined />;
            case 'columnwidthoutlined': return <ColumnWidthOutlined />;
            case 'columnheightoutlined': return <ColumnHeightOutlined />;
            case 'areachartoutlined': return <AreaChartOutlined />;
            case 'piechartoutlined': return <PieChartOutlined />;
            case 'barchartoutlined': return <BarChartOutlined />;
            case 'dotchartoutlined': return <DotChartOutlined />;
            case 'linechartoutlined': return <LineChartOutlined />;
            case 'radarchartoutlined': return <RadarChartOutlined />;
            case 'heatmapoutlined': return <HeatMapOutlined />;
            case 'falloutlined': return <FallOutlined />;
            case 'riseoutlined': return <RiseOutlined />;
            case 'stockoutlined': return <StockOutlined />;
            case 'boxplotoutlined': return <BoxPlotOutlined />;
            case 'fundoutlined': return <FundOutlined />;
            case 'slidersoutlined': return <SlidersOutlined />;
            case 'appleoutlined': return <AppleOutlined />;
            case 'windowsoutlined': return <WindowsOutlined />;
            case 'ieoutlined': return <IeOutlined />;
            case 'chromeoutlined': return <ChromeOutlined />;
            case 'githuboutlined': return <GithubOutlined />;
            case 'aliwangwangoutlined': return <AliwangwangOutlined />;
            case 'dingdingoutlined': return <DingdingOutlined />;
            case 'weibosquareoutlined': return <WeiboSquareOutlined />;
            case 'weibocircleoutlined': return <WeiboCircleOutlined />;
            case 'taobaocircleoutlined': return <TaobaoCircleOutlined />;
            case 'html5outlined': return <Html5Outlined />;
            case 'weibooutlined': return <WeiboOutlined />;
            case 'twitteroutlined': return <TwitterOutlined />;
            case 'wechatoutlined': return <WechatOutlined />;
            case 'whatsappoutlined': return <WhatsAppOutlined />;
            case 'youtubeoutlined': return <YoutubeOutlined />;
            case 'alipaycircleoutlined': return <AlipayCircleOutlined />;
            case 'taobaooutlined': return <TaobaoOutlined />;
            case 'dingtalkoutlined': return <DingtalkOutlined />;
            case 'skypeoutlined': return <SkypeOutlined />;
            case 'qqoutlined': return <QqOutlined />;
            case 'mediumworkmarkoutlined': return <MediumWorkmarkOutlined />;
            case 'gitlaboutlined': return <GitlabOutlined />;
            case 'mediumoutlined': return <MediumOutlined />;
            case 'linkedinoutlined': return <LinkedinOutlined />;
            case 'googleplusoutlined': return <GooglePlusOutlined />;
            case 'dropboxoutlined': return <DropboxOutlined />;
            case 'facebookoutlined': return <FacebookOutlined />;
            case 'codepenoutlined': return <CodepenOutlined />;
            case 'codesandboxoutlined': return <CodeSandboxOutlined />;
            case 'amazonoutlined': return <AmazonOutlined />;
            case 'googleoutlined': return <GoogleOutlined />;
            case 'codepencircleoutlined': return <CodepenCircleOutlined />;
            case 'alipayoutlined': return <AlipayOutlined />;
            case 'antdesignoutlined': return <AntDesignOutlined />;
            case 'antcloudoutlined': return <AntCloudOutlined />;
            case 'aliyunoutlined': return <AliyunOutlined />;
            case 'zhihuoutlined': return <ZhihuOutlined />;
            case 'slackoutlined': return <SlackOutlined />;
            case 'slacksquareoutlined': return <SlackSquareOutlined />;
            case 'behanceoutlined': return <BehanceOutlined />;
            case 'behancesquareoutlined': return <BehanceSquareOutlined />;
            case 'dribbbleoutlined': return <DribbbleOutlined />;
            case 'dribbblesquareoutlined': return <DribbbleSquareOutlined />;
            case 'instagramoutlined': return <InstagramOutlined />;
            case 'yuqueoutlined': return <YuqueOutlined />;
            case 'alibabaoutlined': return <AlibabaOutlined />;
            case 'yahoooutlined': return <YahooOutlined />;
            case 'redditoutlined': return <RedditOutlined />;
            case 'sketchoutlined': return <SketchOutlined />;
            case 'wechatworkoutlined': return <WechatWorkOutlined />;
            case 'openaioutlined': return <OpenAIOutlined />;
            case 'discordoutlined': return <DiscordOutlined />;
            case 'xoutlined': return <XOutlined />;
            case 'bilibilioutlined': return <BilibiliOutlined />;
            case 'pinterestoutlined': return <PinterestOutlined />;
            case 'tiktokoutlined': return <TikTokOutlined />;
            case 'spotifyoutlined': return <SpotifyOutlined />;
            case 'twitchoutlined': return <TwitchOutlined />;
            case 'linuxoutlined': return <LinuxOutlined />;
            case 'javaoutlined': return <JavaOutlined />;
            case 'javascriptoutlined': return <JavaScriptOutlined />;
            case 'pythonoutlined': return <PythonOutlined />;
            case 'rubyoutlined': return <RubyOutlined />;
            case 'dotnetoutlined': return <DotNetOutlined />;
            case 'kubernetesoutlined': return <KubernetesOutlined />;
            case 'dockeroutlined': return <DockerOutlined />;
            case 'baiduoutlined': return <BaiduOutlined />;
            case 'harmonyosoutlined': return <HarmonyOSOutlined />;
            case 'aimoutlined': return <AimOutlined />;
            case 'alertoutlined': return <AlertOutlined />;
            case 'apartmentoutlined': return <ApartmentOutlined />;
            case 'apioutlined': return <ApiOutlined />;
            case 'appstoreaddoutlined': return <AppstoreAddOutlined />;
            case 'appstoreoutlined': return <AppstoreOutlined />;
            case 'audiooutlined': return <AudioOutlined />;
            case 'audiomutedoutlined': return <AudioMutedOutlined />;
            case 'auditoutlined': return <AuditOutlined />;
            case 'bankoutlined': return <BankOutlined />;
            case 'barcodeoutlined': return <BarcodeOutlined />;
            case 'barsoutlined': return <BarsOutlined />;
            case 'belloutlined': return <BellOutlined />;
            case 'blockoutlined': return <BlockOutlined />;
            case 'bookoutlined': return <BookOutlined />;
            case 'borderoutlined': return <BorderOutlined />;
            case 'borderlesstableoutlined': return <BorderlessTableOutlined />;
            case 'branchesoutlined': return <BranchesOutlined />;
            case 'bugoutlined': return <BugOutlined />;
            case 'buildoutlined': return <BuildOutlined />;
            case 'bulboutlined': return <BulbOutlined />;
            case 'calculatoroutlined': return <CalculatorOutlined />;
            case 'calendaroutlined': return <CalendarOutlined />;
            case 'cameraoutlined': return <CameraOutlined />;
            case 'caroutlined': return <CarOutlined />;
            case 'carryoutoutlined': return <CarryOutOutlined />;
            case 'cicircleoutlined': return <CiCircleOutlined />;
            case 'cioutlined': return <CiOutlined />;
            case 'clearoutlined': return <ClearOutlined />;
            case 'clouddownloadoutlined': return <CloudDownloadOutlined />;
            case 'cloudoutlined': return <CloudOutlined />;
            case 'cloudserveroutlined': return <CloudServerOutlined />;
            case 'cloudsyncoutlined': return <CloudSyncOutlined />;
            case 'clouduploadoutlined': return <CloudUploadOutlined />;
            case 'clusteroutlined': return <ClusterOutlined />;
            case 'codeoutlined': return <CodeOutlined />;
            case 'coffeeoutlined': return <CoffeeOutlined />;
            case 'commentoutlined': return <CommentOutlined />;
            case 'compassoutlined': return <CompassOutlined />;
            case 'compressoutlined': return <CompressOutlined />;
            case 'consolesqloutlined': return <ConsoleSqlOutlined />;
            case 'contactsoutlined': return <ContactsOutlined />;
            case 'containeroutlined': return <ContainerOutlined />;
            case 'controloutlined': return <ControlOutlined />;
            case 'copyrightoutlined': return <CopyrightOutlined />;
            case 'creditcardoutlined': return <CreditCardOutlined />;
            case 'crownoutlined': return <CrownOutlined />;
            case 'customerserviceoutlined': return <CustomerServiceOutlined />;
            case 'dashboardoutlined': return <DashboardOutlined />;
            case 'databaseoutlined': return <DatabaseOutlined />;
            case 'deletecolumnoutlined': return <DeleteColumnOutlined />;
            case 'deleterowoutlined': return <DeleteRowOutlined />;
            case 'deliveredprocedureoutlined': return <DeliveredProcedureOutlined />;
            case 'deploymentunitoutlined': return <DeploymentUnitOutlined />;
            case 'desktopoutlined': return <DesktopOutlined />;
            case 'disconnectoutlined': return <DisconnectOutlined />;
            case 'dislikeoutlined': return <DislikeOutlined />;
            case 'dollaroutlined': return <DollarOutlined />;
            case 'downloadoutlined': return <DownloadOutlined />;
            case 'ellipsisoutlined': return <EllipsisOutlined />;
            case 'environmentoutlined': return <EnvironmentOutlined />;
            case 'eurocircleoutlined': return <EuroCircleOutlined />;
            case 'eurooutlined': return <EuroOutlined />;
            case 'exceptionoutlined': return <ExceptionOutlined />;
            case 'expandaltoutlined': return <ExpandAltOutlined />;
            case 'expandoutlined': return <ExpandOutlined />;
            case 'experimentoutlined': return <ExperimentOutlined />;
            case 'exportoutlined': return <ExportOutlined />;
            case 'eyeoutlined': return <EyeOutlined />;
            case 'eyeinvisibleoutlined': return <EyeInvisibleOutlined />;
            case 'fieldbinaryoutlined': return <FieldBinaryOutlined />;
            case 'fieldnumberoutlined': return <FieldNumberOutlined />;
            case 'fieldstringoutlined': return <FieldStringOutlined />;
            case 'fieldtimeoutlined': return <FieldTimeOutlined />;
            case 'fileaddoutlined': return <FileAddOutlined />;
            case 'filedoneoutlined': return <FileDoneOutlined />;
            case 'fileexceloutlined': return <FileExcelOutlined />;
            case 'fileexclamationoutlined': return <FileExclamationOutlined />;
            case 'fileoutlined': return <FileOutlined />;
            case 'filegifoutlined': return <FileGifOutlined />;
            case 'fileimageoutlined': return <FileImageOutlined />;
            case 'filejpgoutlined': return <FileJpgOutlined />;
            case 'filemarkdownoutlined': return <FileMarkdownOutlined />;
            case 'filepdfoutlined': return <FilePdfOutlined />;
            case 'filepptoutlined': return <FilePptOutlined />;
            case 'fileprotectoutlined': return <FileProtectOutlined />;
            case 'filesearchoutlined': return <FileSearchOutlined />;
            case 'filesyncoutlined': return <FileSyncOutlined />;
            case 'filetextoutlined': return <FileTextOutlined />;
            case 'fileunknownoutlined': return <FileUnknownOutlined />;
            case 'filewordoutlined': return <FileWordOutlined />;
            case 'filezipoutlined': return <FileZipOutlined />;
            case 'filteroutlined': return <FilterOutlined />;
            case 'fireoutlined': return <FireOutlined />;
            case 'flagoutlined': return <FlagOutlined />;
            case 'folderaddoutlined': return <FolderAddOutlined />;
            case 'folderoutlined': return <FolderOutlined />;
            case 'folderopenoutlined': return <FolderOpenOutlined />;
            case 'folderviewoutlined': return <FolderViewOutlined />;
            case 'forkoutlined': return <ForkOutlined />;
            case 'formatpainteroutlined': return <FormatPainterOutlined />;
            case 'frownoutlined': return <FrownOutlined />;
            case 'functionoutlined': return <FunctionOutlined />;
            case 'fundprojectionscreenoutlined': return <FundProjectionScreenOutlined />;
            case 'fundviewoutlined': return <FundViewOutlined />;
            case 'funnelplotoutlined': return <FunnelPlotOutlined />;
            case 'gatewayoutlined': return <GatewayOutlined />;
            case 'gifoutlined': return <GifOutlined />;
            case 'giftoutlined': return <GiftOutlined />;
            case 'globaloutlined': return <GlobalOutlined />;
            case 'goldoutlined': return <GoldOutlined />;
            case 'groupoutlined': return <GroupOutlined />;
            case 'hddoutlined': return <HddOutlined />;
            case 'heartoutlined': return <HeartOutlined />;
            case 'historyoutlined': return <HistoryOutlined />;
            case 'holderoutlined': return <HolderOutlined />;
            case 'homeoutlined': return <HomeOutlined />;
            case 'hourglassoutlined': return <HourglassOutlined />;
            case 'idcardoutlined': return <IdcardOutlined />;
            case 'importoutlined': return <ImportOutlined />;
            case 'inboxoutlined': return <InboxOutlined />;
            case 'insertrowaboveoutlined': return <InsertRowAboveOutlined />;
            case 'insertrowbelowoutlined': return <InsertRowBelowOutlined />;
            case 'insertrowleftoutlined': return <InsertRowLeftOutlined />;
            case 'insertrowrightoutlined': return <InsertRowRightOutlined />;
            case 'insuranceoutlined': return <InsuranceOutlined />;
            case 'interactionoutlined': return <InteractionOutlined />;
            case 'keyoutlined': return <KeyOutlined />;
            case 'laptopoutlined': return <LaptopOutlined />;
            case 'layoutoutlined': return <LayoutOutlined />;
            case 'likeoutlined': return <LikeOutlined />;
            case 'lineoutlined': return <LineOutlined />;
            case 'linkoutlined': return <LinkOutlined />;
            case 'loading3quartersoutlined': return <Loading3QuartersOutlined />;
            case 'loadingoutlined': return <LoadingOutlined />;
            case 'lockoutlined': return <LockOutlined />;
            case 'maccommandoutlined': return <MacCommandOutlined />;
            case 'mailoutlined': return <MailOutlined />;
            case 'manoutlined': return <ManOutlined />;
            case 'medicineboxoutlined': return <MedicineBoxOutlined />;
            case 'mehoutlined': return <MehOutlined />;
            case 'menuoutlined': return <MenuOutlined />;
            case 'mergecellsoutlined': return <MergeCellsOutlined />;
            case 'mergeoutlined': return <MergeOutlined />;
            case 'messageoutlined': return <MessageOutlined />;
            case 'mobileoutlined': return <MobileOutlined />;
            case 'moneycollectoutlined': return <MoneyCollectOutlined />;
            case 'monitoroutlined': return <MonitorOutlined />;
            case 'moonoutlined': return <MoonOutlined />;
            case 'moreoutlined': return <MoreOutlined />;
            case 'mutedoutlined': return <MutedOutlined />;
            case 'nodecollapseoutlined': return <NodeCollapseOutlined />;
            case 'nodeexpandoutlined': return <NodeExpandOutlined />;
            case 'nodeindexoutlined': return <NodeIndexOutlined />;
            case 'notificationoutlined': return <NotificationOutlined />;
            case 'numberoutlined': return <NumberOutlined />;
            case 'onetooneoutlined': return <OneToOneOutlined />;
            case 'paperclipoutlined': return <PaperClipOutlined />;
            case 'partitionoutlined': return <PartitionOutlined />;
            case 'paycircleoutlined': return <PayCircleOutlined />;
            case 'percentageoutlined': return <PercentageOutlined />;
            case 'phoneoutlined': return <PhoneOutlined />;
            case 'pictureoutlined': return <PictureOutlined />;
            case 'playsquareoutlined': return <PlaySquareOutlined />;
            case 'poundcircleoutlined': return <PoundCircleOutlined />;
            case 'poundoutlined': return <PoundOutlined />;
            case 'poweroffoutlined': return <PoweroffOutlined />;
            case 'printeroutlined': return <PrinterOutlined />;
            case 'productoutlined': return <ProductOutlined />;
            case 'profileoutlined': return <ProfileOutlined />;
            case 'projectoutlined': return <ProjectOutlined />;
            case 'propertysafetyoutlined': return <PropertySafetyOutlined />;
            case 'pullrequestoutlined': return <PullRequestOutlined />;
            case 'pushpinoutlined': return <PushpinOutlined />;
            case 'qrcodeoutlined': return <QrcodeOutlined />;
            case 'readoutlined': return <ReadOutlined />;
            case 'reconciliationoutlined': return <ReconciliationOutlined />;
            case 'redenvelopeoutlined': return <RedEnvelopeOutlined />;
            case 'reloadoutlined': return <ReloadOutlined />;
            case 'restoutlined': return <RestOutlined />;
            case 'robotoutlined': return <RobotOutlined />;
            case 'rocketoutlined': return <RocketOutlined />;
            case 'rotateleftoutlined': return <RotateLeftOutlined />;
            case 'rotaterightoutlined': return <RotateRightOutlined />;
            case 'safetycertificateoutlined': return <SafetyCertificateOutlined />;
            case 'safetyoutlined': return <SafetyOutlined />;
            case 'saveoutlined': return <SaveOutlined />;
            case 'scanoutlined': return <ScanOutlined />;
            case 'scheduleoutlined': return <ScheduleOutlined />;
            case 'searchoutlined': return <SearchOutlined />;
            case 'securityscanoutlined': return <SecurityScanOutlined />;
            case 'selectoutlined': return <SelectOutlined />;
            case 'sendoutlined': return <SendOutlined />;
            case 'settingoutlined': return <SettingOutlined />;
            case 'shakeoutlined': return <ShakeOutlined />;
            case 'sharealtoutlined': return <ShareAltOutlined />;
            case 'shopoutlined': return <ShopOutlined />;
            case 'shoppingcartoutlined': return <ShoppingCartOutlined />;
            case 'shoppingoutlined': return <ShoppingOutlined />;
            case 'signatureoutlined': return <SignatureOutlined />;
            case 'sisternodeoutlined': return <SisternodeOutlined />;
            case 'skinoutlined': return <SkinOutlined />;
            case 'smileoutlined': return <SmileOutlined />;
            case 'solutionoutlined': return <SolutionOutlined />;
            case 'soundoutlined': return <SoundOutlined />;
            case 'splitcellsoutlined': return <SplitCellsOutlined />;
            case 'staroutlined': return <StarOutlined />;
            case 'subnodeoutlined': return <SubnodeOutlined />;
            case 'sunoutlined': return <SunOutlined />;
            case 'switcheroutlined': return <SwitcherOutlined />;
            case 'syncoutlined': return <SyncOutlined />;
            case 'tableoutlined': return <TableOutlined />;
            case 'tabletoutlined': return <TabletOutlined />;
            case 'tagoutlined': return <TagOutlined />;
            case 'tagsoutlined': return <TagsOutlined />;
            case 'teamoutlined': return <TeamOutlined />;
            case 'thunderboltoutlined': return <ThunderboltOutlined />;
            case 'totopoutlined': return <ToTopOutlined />;
            case 'tooloutlined': return <ToolOutlined />;
            case 'trademarkcircleoutlined': return <TrademarkCircleOutlined />;
            case 'trademarkoutlined': return <TrademarkOutlined />;
            case 'transactionoutlined': return <TransactionOutlined />;
            case 'translationoutlined': return <TranslationOutlined />;
            case 'trophyoutlined': return <TrophyOutlined />;
            case 'truckoutlined': return <TruckOutlined />;
            case 'ungroupoutlined': return <UngroupOutlined />;
            case 'unlockoutlined': return <UnlockOutlined />;
            case 'uploadoutlined': return <UploadOutlined />;
            case 'usboutlined': return <UsbOutlined />;
            case 'useraddoutlined': return <UserAddOutlined />;
            case 'userdeleteoutlined': return <UserDeleteOutlined />;
            case 'useroutlined': return <UserOutlined />;
            case 'userswitchoutlined': return <UserSwitchOutlined />;
            case 'usergroupaddoutlined': return <UsergroupAddOutlined />;
            case 'usergroupdeleteoutlined': return <UsergroupDeleteOutlined />;
            case 'verifiedoutlined': return <VerifiedOutlined />;
            case 'videocameraaddoutlined': return <VideoCameraAddOutlined />;
            case 'videocameraoutlined': return <VideoCameraOutlined />;
            case 'walletoutlined': return <WalletOutlined />;
            case 'wifioutlined': return <WifiOutlined />;
            case 'womanoutlined': return <WomanOutlined />;
            default: return null;

        }

    }

    public render() {
        const { currentURL, menuData } = this.props;

        //const items: MenuItem[] = [
        //    getItem('Dashboard', 'hDashboard', <FundProjectionScreenOutlined />,
        //        [
        //            getItem(<NavLink tag={Link} className="" to="/Dashboard/VN" > Dashboard VN</NavLink >, '/Dashboard VN', null),
        //            getItem(<NavLink tag={Link} className="" to="/Dashboard/External" > Dashboard External</NavLink >, '/Dashboard External​', null)
        //        ]
        //    ),
        //    getItem('Upload Data', 'upload', <UploadOutlined />,
        //        [
        //            getItem(<NavLink tag={Link} className="" to="/Project" >Project</NavLink >, '/RegisterProject', null),

        //            getItem(<NavLink tag={Link} className="" to="/UploadFile" > Upload Data</NavLink >, '/UploadFile', null),
        //        ]),
        //];
        let _currentURL = currentURL;
        const menuAuto = (data: any, menu: MenuItem[]) => {
            console.log(currentURL === '/UploadFile/11')
            data == [] ? null : data.map((d: any) => {
                if (d.type == 'group') {
                    let subMenu: MenuItem[] = [];

                    if (d.children) {
                        d.children == [] ? null : d.children.map((sd: any) => {
                            if (sd.type == 'link') {
                                subMenu.push(getItem(<NavLink tag={Link} className="" to={sd.path} >{sd.label}</NavLink >, sd.path == null || sd.path == undefined ? sd.key : sd.path, this.IconProvider(sd.icon)))
                            }
                        })
                    }

                    menu.push(getItem(d.label, d.key, this.IconProvider(d.icon), subMenu))
                } else if (d.type == 'link') {
                    menu.push(getItem(<NavLink tag={Link} className="" to={d.path} style={{ paddingLeft: 0 }} >{d.label}</NavLink>, d.path == null || d.path == undefined ? d.key : d.path, this.IconProvider(d.icon)))

                }
            })
            return menu;
        }


        return (
            <>
                <Menu theme="dark"
                    defaultSelectedKeys={[_currentURL]}
                    mode="inline"
                    items={menuAuto(menuData, [])}
        
                    onChange={() => window.scrollTo(0, 0) }
                />
            </>
        );
    }
}
