interface DecodedToken {
    ver: string;
    iss: string;
    sub: string;
    aud: string;
    exp: number;
    nonce: string;
    iat: number;
    auth_time: number;
    name: string;
    given_name: string;
    family_name: string;
    tfp: string;
    nbf: number;
}

export interface User {
    displayName: string;
    mail: string;
    jobTitle: string;
    mobilePhone: string;
    officeLocation: string;
}



export const decodeToken = (token: string): User | null => {
    try {
        const [, payloadBase64] = token.split('.');
        const decodedPayload = atob(payloadBase64);
        const decodedToken: DecodedToken = JSON.parse(decodedPayload);

        let userMapping: User = {
            displayName: decodedToken.given_name,
            mail: decodedToken.name,
            jobTitle: decodedToken.tfp,
            mobilePhone: '',
            officeLocation:'',
        }

        return userMapping;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

//const TokenDecoder: React.FC<{ token: string }> = ({ token }) => {
//    const decodedToken = decodeToken(token);

//    return (
//        <div>
//            {decodedToken ? (
//                <pre>{JSON.stringify(decodedToken, null, 2)}</pre>
//            ) : (
//                <p>Error decoding token</p>
//            )}
//        </div>
//    );
//};

//export default decodeToken;