const uri:any = {
    getprojects: "/api/ProjectDataService/GetProjects",
    GetProjectById: "/api/ProjectDataService/GetProjectById",
    GetSheetById: "/api/ProjectDataService/GetSheetById",
    GetUploadsByProjectIdAndCreator: "/api/ProjectDataService/GetUploadsByProjectIdAndCreator",
    getSheets: "/api/ProjectDataService/GetSheets",
    ActivedTopic: "/api/ProjectDataService/ActivedTopic",
    checkUploadStatusByUploadId: "/api/ProjectDataService/CheckUploadStatusByUploadId",
    user: "/api/User",
   
    }
export default (uri);