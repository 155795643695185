import * as React from 'react';
import './Feedback.css';


class Feedback extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
        
        }
    }

   

    public render() {

        



        return (<>
            <iframe
                //width="640px"
                //height="480px"
                src="https://forms.office.com/r/q5hCCZtrrP?embed=true"
                //frameborder="0"
                //marginWidth="0"
                //marginHeight="0"
                //style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
                allowFullScreen
                //webkitallowfullscreen
                //mozallowfullscreen
                //msallowfullscreen
                className={ "fb-msform"}
            />
        </>
        );
    }
}

export default (Feedback);