import * as React  from 'react';
import { Route } from 'react-router';
import MainLayout from './components/Layout/Layout';
import LoginLayout from './components/Layout/LoginLayout';
import Home from './components/Home';
import Counter from './components/Counter';
//import FetchData from './components/FetchData';
import DashboardRoute from './components/Dashboard/DashboardRoute';
import Dashboard from './components/Dashboard/Dashboard';
import Feedback from './components/Feedback/Feedback';
import Login from './components/Layout/Login';
//import LoginForm from './components/LoginMS';

import './custom.css'
import RegisterProject from './components/RegisterProject/RegisterProject';
//import UploadBlob from './components/UploadBlob/UploadBlob';
import UploadBlobRoute from './components/UploadBlob/UploadBlobRoute';
import RegisterTopicRoute from './components/RegisterTopic/RegisterTopicRoute';

const isLoginStr = window.sessionStorage.getItem('accessToken') ? window.sessionStorage.getItem('accessToken') != null : false;
const isLogin = isLoginStr;

export default () => (

     <>
        {!isLogin ?
            <LoginLayout>
                <Route path='/' component={Login} />
            </LoginLayout>:
            <MainLayout>
                {/*<Route path='/login' component={Login} />*/}
                <Route exact path='/' component={Dashboard} />
                <Route path='/dashboard/:COMPANY' component={DashboardRoute} />
                {/*<Route path='/dashboard2/:COMPANY' component={Dashboard} />*/}
                <Route path='/counter' component={Counter} />
                <Route path='/Project' component={RegisterProject} />
                <Route path='/ProjectTopic' component={RegisterTopicRoute} />
                <Route path='/RegisterTopic/:folder' component={RegisterTopicRoute} />
                <Route path='/UploadFile/:folder' component={UploadBlobRoute} />
                <Route path='/Feedback' component={Feedback} />
                {/*<Route path='/fetch-data' component={FetchData} />*/}
            </MainLayout>
        }

    </>

);




//app.post('/webhook', async (req: Request, res: Response) => {
//    const data = req.body;
//    console.log('���Ѻ����������ؤ:', data);

//    // �����żŢ���������ؤ�����

//    res.json({ status: 'success' });
//});

//app.listen(port, () => {
//    console.log(`���������������ҹ������ ${port}`);
//});