import Service from '../service';
import uri from './local'

const getGetMenus: any =  (value: any) => {
    
    let data_result =  new Promise(resolve => {
        Service.apiPost(uri.getmenus, value)
            .then((data: any) => {

               
                return resolve(data);
            });
    });
    return data_result;
};


const getAuthorizePath: any = (value: any) => {

    let data_result = new Promise(resolve => {
        Service.apiPost(uri.GetAuthorizePath, value)
            .then((data: any) => {


                return resolve(data);
            });
    });
    return data_result;
};



const getLoginConfig: any = (value: any) => {

    let data_result = new Promise(resolve => {
        Service.apiGet(uri.AppAuthen, value)
            .then((data: any) => {


                return resolve(data);
            });
    });
    return data_result;
};


export default {
    getGetMenus, getAuthorizePath, getLoginConfig
};
