import { Modal } from 'antd';
import Service from '../service';
import uri from './local'


const getembedinfo: any = async (COMPANY: any) => {
    //console.log(COMPANY)
    let data_result = await new Promise(resolve => {
        Service.apiPost(uri.getembedinfo, COMPANY)
            .then((data: any) => {

               
                return resolve(data);
            });
    });
    return data_result;
};


export default {
    getembedinfo,
};
