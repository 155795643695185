import React from 'react';
import { decodeToken, User } from './jwtUtils';

const _accessToken = window.sessionStorage.getItem('accessToken')
const accessToken = _accessToken != null ? _accessToken:""

export const getUserProfile = async() => {

    //const isLoginStr = window.sessionStorage.getItem('accessToken') ? window.sessionStorage.getItem('accessToken') != null : false;
    //const isLogin = isLoginStr;

    const accessType = await window.sessionStorage.getItem('accessType')
    let res: User|null=null;

    if (accessType === 'ad') {
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        let azenpoint = 'https://graph.microsoft.com/v1.0/me';

        await  fetch(azenpoint, {
            headers,
        }).then((response) => response.json())
            .then((profile:User) => {
                // Process the user profile data
                //console.log(profile)
                res = profile;
                //console.log(res)

            }).catch(error=>{
                window.sessionStorage.clear();
                window.location.href = '/';

            });
    } else if (accessType === 'b2c') {

        //return decodeToken(accessToken);
        res = await decodeToken(accessToken);
    } else {

        window.sessionStorage.clear();
        window.location.href = '/';
        return {};
    }
    //await console.log(res)
    if (res == null || res?.mail == "" || res?.mail == undefined) {

        window.sessionStorage.clear();
        window.location.href = '/';
    }

    return await res;
}




//export const userIsLogin = async () => {

//    //const isLoginStr = window.sessionStorage.getItem('accessToken') ? window.sessionStorage.getItem('accessToken') != null : false;
//    let isLogin = false;

//    const accessType = await window.sessionStorage.getItem('accessType')
//    console.log(accessType)
//    if (accessType === 'ad') {
//        const headers = {
//            Authorization: `Bearer ${accessToken}`,
//        };

//        let azenpoint = 'https://graph.microsoft.com/v1.0/me';

//        await  fetch(azenpoint, {
//            headers,
//        }).then((response) => response.json())
//            .then((profile) => {
//                // Process the user profile data
//                if (profile !== null && profile.displayName) {
//                    console.log(accessType, true)
//                    isLogin = true;
//                    window.sessionStorage.setItem('isLogin', JSON.stringify(isLogin))

//                } else {
//                    console.log(accessType, false)
//                    isLogin = false;
//                    window.sessionStorage.setItem('isLogin', JSON.stringify(isLogin))
//                }
//            });

//    } else if (accessType === 'b2c') {

//        let profile = await decodeToken(accessToken);
//        if (profile !== null && profile.displayName) {
//            console.log(accessType, true)
//                    isLogin = true;
//                    window.sessionStorage.setItem('isLogin', JSON.stringify(isLogin))
//        } else {
//            console.log(accessType, false)
//            isLogin = false;
//                    window.sessionStorage.setItem('isLogin', JSON.stringify(isLogin))
//        }


//    } else {
//        //console.log(accessType, isLogin)
//        //return await isLogin;
//        isLogin = false;
//        window.sessionStorage.setItem('isLogin', JSON.stringify(isLogin))
//    }

//    //console.log(accessType, false)
//    return isLogin;
//}




