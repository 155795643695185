// import axios from 'axios'

import { message } from "antd";




// const  apiGet = async (url: any) => {
//     return await axios(url)
//     .then(function(response) {
//       ////////console.log("list apiGet data 1--------------->", response);
//       return response;
//     })
//     .then(data => {
//       ////////console.log("list apiGet data 2--------------->", data);
//       if (data) {
//         return data;
//       }
//       return [];
//     });
//     };

const apiGet =  (url: any,object?:any) => {
  //  //////console.log("list apiGet data 2--------------->", object);
    return  fetch(url,
       {method: "GET",
       body: JSON.stringify(object),
       headers: {"Content-Type": "application/json"}
    })
    .then(function(response) {
      ////////console.log("list apiGet data 1--------------->", response);
      return response.json();
    })
    .then(data => {
      ////////console.log("list apiGet data 2--------------->", data);
      if (data) {
        return data;
      }
      return [];
    });
    };
    
const apiPost = (url: any, object?: any) => {
    // //////console.log("url--------------->", object);
       return  fetch(url, {
       method: "POST",
       body: JSON.stringify(object),
           headers: { 'Accept': 'application/json',"Content-Type": "application/json"}
       })
           .then((resp) => {
               return resp.json()
           })
       .then((data) => {
           if (data) {
              // ////////console.log("list apiPost data--------------->", data);
               return data;
           }
           return [];
           })
       .catch(err => {
           return err;
       });
    };
    
    const apiPut = (url:any, object:any) => {
       //////////console.log("url--------------->", url,object);
       return fetch(url, {
           method: "PUT",
           body: JSON.stringify(object),
           headers: { "Content-Type": "application/json" }
       })
       .then((resp) => { return resp.json() })
       .then((data) => {
           if (data) {
              // ////////console.log("list apiPost data--------------->", data);
               return data;
           }
           return [];
           })
           .catch(err => {
               return err;
           });
};



const apiUploadFile = (url: any, object?: any) => {
    ////console.log("url--------------->", object);
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(object),
        headers: { "Content-Type": "application/json" },
      //  body: JSON.stringify({ title: 'React POST Request Example' })
    })
        .then((resp) => { return resp.json() })
        .then((data) => {
            if (data) {
                // ////////console.log("list apiPost data--------------->", data);
                return data;
            }
            return [];
        })
        .catch(err => {
            return err;
        });
};

const apiGet1_1 = (url: any, object?: any) => {
    //  //////console.log("list apiGet data 2--------------->", object);
    let userString: any = localStorage.getItem('user');
    let user: any = JSON.parse(userString);

    let req = {
        RequestData: object == undefined || object == null ? "-" : object,
        ssoAccountToken: user.data.ssoAccountToken
    }
    //console.log(req)
    return fetch(url,
        {
            method: "GET",
            body: JSON.stringify(req),
            headers: { "Content-Type": "application/json" }
        })
        .then(function (response) {
            ////////console.log("list apiGet data 1--------------->", response);
            return response.json();
        })
        .then(data => {
            ////////console.log("list apiGet data 2--------------->", data);
            if (data) {
                if (data.responseBase.messageType == 0) {
                    return data.responseData;
                }
                else {
                    message.error(data.responseBase.messageTypeName + ':"' + data.responseBase.RequestMethod + '"');
                    //message.error(data.responseBase.messageTypeName);
                }
            }
            return [];
        });
};


const apiPost1_1 = (url: any, object?: any) => {
    // //////console.log("url--------------->", object);

    let userString: any = localStorage.getItem('user');
    let user: any = JSON.parse(userString);

    let req = {
        RequestData: object,
        ssoAccountToken: user.data.ssoAccountToken
    }

    return fetch(url, {
        method: "POST",
        body: JSON.stringify(req),
        headers: { "Content-Type": "application/json" }
    })
        .then((resp) => { return resp.json() })
        .then((data) => {
            if (data) {
                if (data.responseBase.messageType == 0) {
                    return data.responseData;
                }
                else {
                    message.error(data.responseBase.messageTypeName + ':"' + data.responseBase.RequestMethod + '"');
                    //message.error(data.responseBase.messageTypeName);
                }
            }
            //return [];
        })
        .catch(err => {
            return err;
        });
};


const apiPut1_1 = (url: any, object: any) => {
    //////////console.log("url--------------->", url,object);

    let userString: any = localStorage.getItem('user');
    let user: any = JSON.parse(userString);

    let req = {
        RequestData: object,
        ssoAccountToken: user.data.ssoAccountToken
    }

    return fetch(url, {
        method: "PUT",
        body: JSON.stringify(req),
        headers: { "Content-Type": "application/json" }
    })
        .then((resp) => { return resp.json() })
        .then((data) => {
            if (data) {
                if (data.responseBase.messageType == 0) {
                    return data.responseData;
                }
                else {
                    message.error(data.responseBase.messageTypeName + ':"' + data.responseBase.RequestMethod+'"');
                    //message.error(data.responseBase.messageTypeName);
                }
            }
           // return [];
        })
        .catch(err => {
            return err;
        });
};


const apiUploadFile1_1 = (url: any, object?: any) => {
    ////console.log("url--------------->", object);
    let userString: any = localStorage.getItem('user');
    let user: any = JSON.parse(userString);

    let req = {
        RequestData: object,
        ssoAccountToken: user.data.ssoAccountToken
    }
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(req),
        headers: { "Content-Type": "application/json" },
        //  body: JSON.stringify({ title: 'React POST Request Example' })
    })
        .then((resp) => { return resp.json() })
        .then((data) => {
            if (data) {
                if (data.responseBase.messageType == 0) {
                    return data.responseData;
                }
                else {
                    message.error(data.responseBase.messageTypeName + ':"' + data.responseBase.RequestMethod+'"');
                }
            }
           // return [];
        })
        .catch(err => {
            return err;
        });
};

    export default {
    apiGet,
    apiPost,
    apiPut,
    apiUploadFile,
    apiGet1_1,
    apiPost1_1,
    apiPut1_1,
    apiUploadFile1_1
    };
    