import * as React from 'react';
import { Form, Input, Select, Button, Divider, message, UploadProps, TableColumnsType, Table, Checkbox, Modal,} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { getUserProfile } from '../../authen/UserProfile';


const dataTypeItem: any = [
    { label: <span>TEXT</span>, value: 'TEXT' },
    { label: <span>NUMBER</span>, value: 'NUMBER' },
    { label: <span>DATETIME</span>, value: 'DATETIME' },
]

class RegisterTopicForm extends React.Component<any, any> {
    formRef = React.createRef();
    constructor(props: any) {
        super(props);
        this.state = {
            fileList: [],
            selectedFile: [],
            sheetFileList: [],
            fileTempName: "",
            fileName: "",
            formTab: 1,
            sheetColumns: [],
            loading: false,
        }
    }

    success = (msn: any,) => {
        Modal.success({
            title: 'Success',
            content: msn,
        });
    };
    error = (title: any, msn: any) => {
        Modal.error({
            title: title,
            content: msn,
            width:'500px',
        });
    };
    warning = (title: any, msn: any) => {
        Modal.warning({
            title: title,
            content: msn,
        });
    };

    componentWillMount = () => {
        this.setDataForm(this.props)
    }


    handleSubmit = async (e: any) => {
        let userProfile: any = await getUserProfile();
        const { project,  sheets, sheetItem  } = this.props;
        const { fileTempName, fileName, sheetColumns ,} = this.state;
        //const _reloadTable = reloadTable()
        const sheetName = sheets.find((a: any) => a.SheetName == e.topicName && a.SheetID)

        //let formRef: any = this.formRef;

        const sheetID = sheetItem.sheetID;
        const FileName = sheetItem.fileName;
        //block submit button
        this.setState({ loading: true })

        try {
            if (sheetName == undefined || sheetID > 0) {
                let blobName: any = fileName
                const value: any = await {
                    "FileName": fileTempName,
                    "BlobName": blobName ? blobName : FileName,
                    "TopicName": e.topicName,
                    "ContainerName": project.landingPath + "/" + project.projectName + '/' + e.topicName + '/00_templates',
                    "ProjectID": JSON.stringify(project.projectID),
                    "CreatorName": userProfile.mail,
                    "IngestMode": e.ingestMode,
                    "SheetColumns": sheetColumns,

                    "SheetID": sheetID == undefined || sheetID == null ? 0 : sheetID
                };

                let _response = false;
                let _data: any = [];
               await fetch('/api/ProjectDataService/uploadTopic', {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', "Content-Type": "application/json" },
                    body: JSON.stringify(value),
                }).then(response => {

                    _response = response.ok
                    return response.json();
                }).then(data => {
                    console.log(data)
                    _data = JSON.parse(data);
                })
                    .catch(error => {
                        console.error('There has been a problem with your fetch operation:', error);
                    });

                if (!_response) {
                    try {
                        const msg = await _data.detail[0].msg;
                        await this.error('File template error.', msg)
                        this.setState({ loading: false, })
                    } catch {
                        const msg = await _data.detail.msg;
                        await this.error('File template error.', msg)
                        this.setState({ loading: false, })

                    }
                } else {

                    this.resetForm()
                    await this.success('Upload successful')
                }

            } else {

                this.warning('Warning.', 'Topic name is duplicate.')
                this.setState({ loading: false, })

            }
        } catch (error) {
            console.error('Upload error:', error);
                this.setState({ loading: false, })
        }
    };


    setDataForm = async (props: any) => {
        const { sheetItem } = await props;
        let formRef: any = await this.formRef;
        let sheetColumns: any = await [];
        if (sheetItem.sheetID > 0) {
            await formRef.current.setFieldsValue({ topicName: sheetItem.sheetName, ingestMode: sheetItem.ingestMode })

            await sheetItem.sheetColumns.map((d: any, i: any) => {
                sheetColumns.push({
                    key: i,
                    SheetColumnID: d.sheetColumnID,
                    SheetColumnName: d.sheetColumnName,

                    DataType: d.dataType,
                    IsKey: d.isKey,
                    data: d,
                })

            })
            //console.log(sheetColumns)
            await this.setState({ sheetColumns: sheetColumns })

        } else {

            await formRef.current.resetFields()
            await this.setState({ sheetColumns:[] })
        }

    }

    componentWillReceiveProps =async (props: any) => {
        this.setDataForm(props)
    }



    // changeTableIsKey = (e: any, key: any) => {
    //    const { sheetColumns } = this.state;

    //     console.log(e.target.checked)
    //    const updatedArray = sheetColumns.map((item: any) => {
    //        if (item.sheetColumnID === key) {
    //            return { ...item, IsKey: e.target.checked };
    //        }
    //        return item;
    //    });
    //    this.setState({ sheetColumns: updatedArray })

    //}

    // changeTableDataType = (e: any, key: any) => {

    //    const { sheetColumns } = this.state;


    //    const updatedArray = sheetColumns.map((item: any) => {
    //        if (item.sheetColumnID === key) {
    //            return { ...item, DataType: e };
    //        }
    //        return item;
    //    });

    //    this.setState({ sheetColumns: updatedArray })
    //}

    resetForm = () => {
        const { closeForm, reloadTable, } = this.props;
        let formRef: any = this.formRef;
        formRef.current.resetFields()
        this.setState({ sheetColumns: [], loading: false, })
      const _reloadTable  = reloadTable()
        closeForm()
        _reloadTable()

    }


    public render() {
        const { project, formType, } = this.props;
        const { sheetColumns, loading } = this.state;
        let formRef: any = this.formRef;

        const props: UploadProps = {
            name: 'file',
            maxCount: 1,
            //method: 'POST',
            //action: '/api/ProjectDataService/checkuploadfile',
            action: ((file: File) => '/api/ProjectDataService/CheckUploadFileTemplate'),
            onChange(info: any) {
                onChangeUplaod(info);
            }
        }

        const onChangeUplaod = async (info: any) => {
            await this.setState({ sheetColumns: [] })
            if (info.file.response != undefined) {
                let sheetFileList: any = await [];
                let sheetColumns: any = await [];
                let fileTempName: any = await "";
                let fileName: any = await "";

                //console.log(info.file.response)

                if (info.file.response.status == "error") {

                    const msg: any = [];
                    //console.log('info.file.response.detail', info.file.response.exception)

                    const exception = info.file.response.exception ? JSON.parse(info.file.response.exception) : {}

                    exception.detail.map((a: any,i:any) => {
                        msg.push(<div>&nbsp;&nbsp;&nbsp;{a.type + ": " + a.msg}</div>)
                    })


                    let formRef: any = await this.formRef;
                    const topicName = formRef.current.getFieldsValue().topicName
                    const ingestMode = formRef.current.getFieldsValue().ingestMode

                    await formRef.current.resetFields()
                    await formRef.current.setFieldsValue({ topicName: topicName, ingestMode })

                    await this.error('File template error.', <span>{msg}</span>)
                    sheetColumns = await [];

                }


                await info.file.response.sheetNames == undefined ? [] :
                    info.file.response.sheetNames.map((d: any) => {
                        sheetFileList.push({
                            key: d,
                            value: d,
                            label: d,
                        })
                    });

                fileTempName = await info.file.response.fileTempName == ""
                    || info.file.response.fileTempName == null
                    || info.file.response.fileTempName == undefined ? "" :
                    info.file.response.fileTempName;

                fileName = await info.file.response.fileName == ""
                    || info.file.response.fileName == null
                    || info.file.response.fileName == undefined ? "" :
                    info.file.response.fileName;



                info.file.response.sheetColumns == undefined ? null :
                    info.file.response.sheetColumns.map((d: any, i: number) => {
                    sheetColumns.push({
                        key: i,
                        SheetColumnID: d.sheetColumnID,
                        SheetColumnName: d.sheetColumnName,
                        DataType: d.dataType,
                        IsKey: d.isKey,
                        data:d,
                    })
                })


                if (sheetFileList.length > 1) {
                    let formRef: any = await this.formRef;
                    const topicName = formRef.current.getFieldsValue().topicName
                    const ingestMode = formRef.current.getFieldsValue().ingestMode

                    await formRef.current.resetFields()
                    await formRef.current.setFieldsValue({ topicName: topicName, ingestMode })
                    await this.warning('File template not supported.', <span>Uploading template files with more than one sheet is not supported.<div>Please remove any extra sheets before uploading.</div></span>)
                    sheetColumns = await [];
                }
                //console.log(sheetColumns)
                await this.setState({ sheetFileList, fileTempName, fileName, sheetColumns })
            }
        }

        const dataIngestMode: any = [
            { label: <span>Overwrite</span>, value: 'overwrite' },
            { label: <span>Append</span>, value: 'append' },
            { label: <span>Upsert</span>, value: 'upsert' },
        ]

        const changeTableIsKey = (e: any, key: any) => {
            const { sheetColumns } = this.state;

            //console.log(e.target.checked, key);

            const updatedArray = sheetColumns.map((item: any) => {
                console.log(item, key);

                if (item.SheetColumnID === key) {

                    return { ...item, IsKey: e.target.checked };
                }
                return item;
            });
            //console.log(updatedArray);
            this.setState({ sheetColumns: updatedArray })

        }

        const changeTableDataType = (e: any, key: any) => {

            const { sheetColumns } = this.state;

            //console.log(e, key);

            const updatedArray = sheetColumns.map((item: any) => {
                if (item.SheetColumnID === key) {
                    return { ...item, DataType: e };
                }
                return item;
            });
            //console.log(updatedArray);

            this.setState({ sheetColumns: updatedArray })
        }


        const columnSheets: TableColumnsType<any> = [
            {
                title: 'No.',
                dataIndex: 'key',
                render: (key: any) => <span>{key + 1}</span>,
                width: 50,
            },

            //{
            //    title: 'SheetColumnID',
            //    dataIndex: 'SheetColumnID',
            //    render: (key: any) => <span>{key + 1}</span>,
            //    width: 50,
            //},
            {
                title: 'Name',
                dataIndex: 'SheetColumnName',
                render: (text: string) => <a>{text}</a>,
            },
            // {
            //    title: 'Key',
            //     dataIndex: 'IsKeyRender',
            //    width: 50,
            //    //render: (data: any) => <Checkbox defaultChecked={data.isKey} onChange={(e: any) => changeTableIsKey(e, data.sheetColumnID)} />,
            //},
            //{
            //    title: 'DataType',
            //    dataIndex: 'DataTypeRender',
            //    width: 250,
            //    //render: (data: any) => <a>{data.sheetColumnID}</a>,
            //    // render: (data: any) => <Checkbox defaultChecked={data.isKey} onChange={(e: any) => changeTableIsKey(e, data.sheetColumnID)} />,
            //},
            {
                title: 'Key',
                dataIndex: 'data',
                width: 50,
                render: (data: any) => <Checkbox defaultChecked={data.isKey} onChange={(e: any) => changeTableIsKey(e, data.sheetColumnID)} />,
            },
           
            {
                title: 'Data Type',
                dataIndex: 'data',
                width: 250,
                render: (data: any) =>
                    < Select
                        defaultValue={data.dataType}
                        size={"small"}
                        style={{ width: "200px" }}
                        options={dataTypeItem}
                        onChange={(e: any) => changeTableDataType(e, data.sheetColumnID)}
                    />,

            },

        ]


       

        return (

            <>
                <Divider orientation="left"> Register Topic {project.projectName}</Divider>
                <Form ref={formRef} layout="vertical" onFinish={this.handleSubmit} >

                        <>
                            <Form.Item label="Topic" name="topicName" rules={[{ required: true, message: 'Please select!', }]}>
                                <Input />
                        </Form.Item>
                        <Form.Item label="Ingest Mode" name="ingestMode" rules={[{ required: true, message: 'Please select!', }]}>
                            <Select options={dataIngestMode} />
                        </Form.Item>
                        <Form.Item label="Choose file" name="File" rules={[{ required: formType!=="edit", message: 'Please choose file!' }]}>
                                <Dragger{...props}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                    The system only supports <span style={{ color: "#f00" }} >.xlsx</span> files.
                                </p>
                                <p className="ant-upload-hint">
                                    The template file should have exactly one worksheet.
                                </p>
                              
                                </Dragger>

                        </Form.Item>
                            <Table
                            columns={columnSheets}
                            dataSource={sheetColumns}
                            pagination={false}
                            size={'small'}
                            scroll={{ y: 240 }}
                            />

                        </>

                    <div className="clearfix" />
                    <div style={{ float: 'right', paddingTop:'20px' }}>
                        <Button type="primary" htmlType="submit" loading={loading } >Submit</Button>&nbsp;
                        <Button type="default" onClick={this.resetForm}>Close</Button>
                        </div>
                        <div className="clearfix" />
                </Form>

            </>
        );
    }
}

export default (RegisterTopicForm);