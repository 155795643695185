import { DeleteOutlined,  PlusOutlined, } from '@ant-design/icons';
import { Col, Menu, Pagination, Row, Select, Table, TableColumnsType } from 'antd';
import Modal from 'antd/es/modal/Modal';
import * as React from 'react';
import ProjectActionModel from '../../_constant/Project/ProjectActionModel';
import RegisterProjectForm from './RegisterProjectForm';


class RegisterProject extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visibleRegisterProjectForm: false,
            projectList: [],
            projectListTable: [],
            searchProject: [],
            rowProjectSelection: [],
            deleteButtonVisible: false,
            pagination: {},
            pageSize:10,
        }
    }
    handleSubmit = (e: any) => {
        console.log('Submitting project:', { e });
    };

    componentWillMount = () => {
        this.GetProjectsData(null)
        console.log('Submitting project:')
    }

    GetProjectsData = async (e: any) => {
        await console.log(e)
        const { pageSize } = this.state;
        const value: any = await {
            "offset": JSON.stringify(e == null ? 0 : ((e.page - 1) * e.pageSize)),
            "limit": JSON.stringify(e == null ? pageSize : e.pageSize),
            "sort": "ProjectID"
        };
        //ProjectActionModel.getUser(value).then((res: any) => console.log(res));
        await ProjectActionModel.getProjectList(value).then((res: any) => {
            let projectList: any = [];
            let pagination = {} ;

            try {
                res.data == null ? [] : res.data
                    .map((d: any) => projectList.push({

                        key: d.projectID,
                        ProjectID: d.projectID,
                        ProjectName: d.projectName,
                        CompanyCode: d.companyCode,
                        CompanyName: d.companyName,
                        LandingPath: d.landingPath,

                    }));
                pagination = res.pagination

            } catch {
                projectList = [];
            }
            console.log(projectList, res)
            this.setState({ pagination, projectList, projectListTable: projectList,})

        })



    }

    rowSelection = (e: any) => {
        console.log(e)
    }


    onChangeSearch = (e: any) => {

        const { projectList } = this.state;


        let searchProject = e;
        try {

            let projectListTable: any = [];

            if (searchProject.length > 0) {

                searchProject.map((a: any) => {
                    projectList.filter((b: any) => JSON.stringify(b).toLowerCase().trim().includes(a.toLowerCase().trim()))
                        .map((c: any) =>
                            projectListTable.push(c)
                        )
                })


            } else {
                projectListTable = projectList;
            }



            this.setState({ projectListTable })


        }
        catch {

        }

    }

    onClickButtonBar = (e: any) => {
        switch (e.key) {
            case 'create':
                this.setState({ visibleRegisterProjectForm: true })
           break;
        }
        console.log(e)
    }






    public render() {
        const { visibleRegisterProjectForm, projectListTable, deleteButtonVisible, pagination, pageSize } = this.state;

        const handleChangePage = (page: any, pageSize: any) => {
            this.setState({ pageSize })
            this.GetProjectsData({ page, pageSize })
        };

        const columnsProject: TableColumnsType<any> = [
            {
                title: 'ID',
                dataIndex: 'ProjectID',
                width:'90px',
            },
            {
                title: 'ProjectName',
                dataIndex: 'ProjectName',
            },
            {
                title: 'CompanyCode',
                dataIndex: 'CompanyCode',
            },
            {
                title: 'CompanyName',
                dataIndex: 'CompanyName',
            },
            {
                title: 'LandingPath',
                dataIndex: 'LandingPath',
            },

           
        ];

        
        const items: any = [
            {
                label: 'Create',
                key: 'create',
                icon: <PlusOutlined />,
            },
            {
                label: 'Delete',
                key: 'delete',
                icon: <DeleteOutlined />,
                disabled: !deleteButtonVisible,
            },
        
        ];

        const rowSelection = {
            onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                selectedRows !== null && selectedRows.length==0 ? this.setState({ rowProjectSelection: [], deleteButtonVisible: false }) : this.setState({ rowProjectSelection: selectedRows, deleteButtonVisible: true });
                console.log(selectedRows !== null , selectedRows.length == 0);
                
            },
            getCheckboxProps: (record: any) => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        return (<>

          
            <Row style={{ padding: "10px" }}>
                <Col
                    key="Search"
                    span={24} >
                    <Menu
                        selectedKeys={["0"]}
                        mode="horizontal"
                        items={items}
                        onClick={(e: any) => this.onClickButtonBar(e)}
                        defaultChecked
                    />
                </Col>
            </Row>
            <Row style={{ padding:"5px" }}>
                <Col
                    key="Search"
                    span={6} >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Search"
                        showSearch
                        onChange={(e: any) => this.onChangeSearch(e)}
                        size={"small"}
                    />
                </Col>
            </Row>

            <Table
                columns={columnsProject}
                dataSource={projectListTable}
                size="small"
                pagination={false}
             
                sortDirections={[]}
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
            />
            {
                <Pagination
                    size="small"
                    total={pagination.total}
                    pageSizeOptions={[10, 50, 100]}
                    onChange={handleChangePage}
                    pageSize={pageSize}
                    style={{ float: "right" }}
                />
            }






            {/* Form Area */}
            <Modal
                visible={visibleRegisterProjectForm}
                footer={false}
                onCancel={() => this.setState({ visibleRegisterProjectForm: false })}
            >
                {/*<RegisterProjectForm />*/}
            </Modal>
        </>
        );
    }
}

export default (RegisterProject);