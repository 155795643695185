import Service from '../service';
import uri from './local'
//import

const getProjectList: any =  (value: any) => {
    
    let data_result =  new Promise(resolve => {
        Service.apiPost(uri.getprojects, value)
            .then((data: any) => {

               
                return resolve(data.responseData);
            });
    });
    return data_result;
};


const getProjectById: any = (value: any) => {
    //console.log(value)
    let data_result = new Promise(resolve => {
        Service.apiPost(uri.GetProjectById, value)
            .then((data: any) => {


                return resolve(data.responseData);
            });
    });
    return data_result;
};

const activedTopic: any = (value: any) => {
    //console.log(value)
    let data_result = new Promise(resolve => {
        Service.apiPost(uri.ActivedTopic, value)
            .then((data: any) => {
                //console.log(data)
                return resolve(data.responseData);
            });
    });
    return data_result;
};



const getSheetById: any = (value: any) => {
    //console.log(value)
    let data_result = new Promise(resolve => {
        Service.apiPost(uri.GetSheetById, value)
            .then((data: any) => {


                return resolve(data.responseData);
            });
    });
    return data_result;
};


const getUploadList: any = (value: any) => {

    let data_result = new Promise(resolve => {
        Service.apiPost(uri.GetUploadsByProjectIdAndCreator, value)
            .then((data: any) => {

                
                return resolve(data.responseData);
            });
    });
    return data_result;
};

const getSheetList: any = (value: any) => {

    //let req = {
    //    requestData: value,
    //    requestEndpoint: config.apiEndpoint
    //}

    let data_result = new Promise(resolve => {
        Service.apiPost(uri.getSheets, value)
            .then((data: any) => {


                return resolve(data.responseData);
            });
    });
    return data_result;
};


const CheckUploadStatusByUploadId: any = (value: any) => {

    //let req = {
    //    requestData: value,
    //    requestEndpoint: config.apiEndpoint
    //}

    let data_result = new Promise(resolve => {
        Service.apiPost(uri.getSheets, value)
            .then((data: any) => {


                return resolve(data.responseData);
            });
    });
    return data_result;
};

export default {
    getProjectList, getUploadList, getSheetList, getProjectById, CheckUploadStatusByUploadId, getSheetById, activedTopic
};
