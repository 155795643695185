import {  Menu, Layout, MenuProps, Avatar,ConfigProvider, Drawer, Button, Spin } from 'antd';
import React from 'react';
import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser'

const { Header, Content, Sider } = Layout;
import {
    UserOutlined,
} from '@ant-design/icons';
import {
    Link,
} from 'react-router-dom';
import { NavbarBrand, NavLink } from 'reactstrap';
type MenuItem = Required<MenuProps>['items'][number];
import { getUserProfile } from '../../authen/UserProfile';
import logoTHEARCH from '../../SCGLogo/THEARCH.png';
import MainMenu from './MainMenu';
import AutherizeActionModel from '../../_constant/Authorization/AuthorizeActionModel';


function handleClick(e: any) {
    console.log(e.keyPath[0])
    window.location.href = e.keyPath[0]
}
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const isLoginStr = window.sessionStorage.getItem('accessToken') ? window.sessionStorage.getItem('accessToken') != null : false;
const isLogin = isLoginStr;


function createPublicClientApplication(config: any) {
    //console.log(config)

    return new PublicClientApplication({
        auth: {
            clientId: config.clientId,
            redirectUri: config.redirectUri,
            authority: config.authority,
        },
        cache: {
            cacheLocation:
                BrowserCacheLocation.SessionStorage,
            storeAuthStateInCookie: true,
        },
    });
}

//const menuData: any = [
//    {
//        label: "Dashboard", path: "", key: "hDashboard", icon: "FundProjectionScreenOutlined",type:"group",
//        children: [
//            { label: "Dashboard VN", path: "/Dashboard/VN", key: "'/Dashboard VN'", icon: "", type:"link" }
//            , { label: "Dashboard External", path: "/Dashboard/External", key: "/Dashboard External", icon: "", type:"link" }
//        ]
//    },


//     {
//         label: "Upload Data", path: "", key: "upload", icon: "UploadOutlined", type: "group",
//        children: [
//            { label: "chem-datauploadcenter", path: "/UploadFile/chem-datauploadcenter", key: '/chem-datauploadcenter', icon: "", type: "link" },
//            { label: "chem-vnpmm", path: "/UploadFile/vnpmm", key: '/chem-vnpmm', icon: "", type: "link" },
//            { label: "chem-vnpmm-ajplast", path: "/UploadFile/9", key: '/chem-vnpmm-ajplast', icon: "", type: "link" },
//            { label: "chem-vnpmm-alcamax", path: "/UploadFile/10", key: '/chem-vnpmm-alcamax', icon: "", type: "link" },
//            { label: "chem-vnpmm-buulong", path: "/UploadFile/vnpmm-buulong", key: '/chem-vnpmm-buulong', icon: "", type: "link" },
//            { label: "chem-vnpmm-cbmv", path: "/UploadFile/vnpmm-cbmv", key: '/chem-vnpmm-cbmv', icon: "", type: "link" },
//            { label: "chem-vnpmm-crvc", path: "/UploadFile/vnpmm-crvc", key: '/chem-vnpmm-crvc', icon: "", type: "link" },
//            { label: "chem-vnpmm-duytan", path: "/UploadFile/vnpmm-duytan", key: '/chem-vnpmm-duytan', icon: "", type: "link" },
//            { label: "chem-vnpmm-vkpc", path: "/UploadFile/vnpmm-vkpc", key: '/chem-vnpmm-vkpc', icon: "", type: "link" },
//        ]
//    },

//    {
//        label: "Digital Admin", path: "", key: "admin", icon: "UserOutlined", type: "group",
//        children: [
//            { label: "Project Register", path: "/Project", key: '/Project', icon: "", type: "link" }
//        ]
//    }


//];


//'rgb(4, 153, 131)',//เขียว SCGC
//'rgb(50, 49, 129)',//น้ำเงิน SCGC
//'rgb(237, 28, 36)',//แดง SCGC
//rgb(250 166 26),//เหลือง SCGC
//rgb(191 191 191),//เทา

export default class MainLayout extends React.PureComponent<{}, any> {
    //publicClientApplication: PublicClientApplication;
    constructor(props: any) {
        super(props);
        this.state = {
            ...this.state,
            currentURL: "",
            collapseSider: false,
            isLogin: isLogin,
            loginVisible: false,
            userProfile: null,
            menuDataList: [],
            spinning:false,
        }

        //this.publicClientApplication = new PublicClientApplication({
        //    auth: {
        //        clientId: config.clientId,
        //        redirectUri: config.redirectUri,
        //        authority: config.authority,
        //    },
        //    cache: {
        //        cacheLocation: BrowserCacheLocation.SessionStorage,
        //        storeAuthStateInCookie: true,

        //    }
        //})
    }
    componentDidMount = () => {
        this.setState({ spinning: true })

        AutherizeActionModel.getLoginConfig().then((res: any) => {

            //console.log(res)
            this.setState({ conf: res, spinning: false })
        })
    }
    componentWillMount = async () => {
        const {  pathname } = location;
        let collapseSiderStr = await window.localStorage.getItem("collapseSider");
        let collapseSider = collapseSiderStr == null ? false : JSON.parse(collapseSiderStr)
        let userProfile: any = await getUserProfile();
        await this.setState({ currentURL: pathname, collapseSider, userProfile })
        console.log(pathname)
        await this.getMenus()



    }

    getMenus = async () => {
        let userProfile: any = await getUserProfile();
        let menuDataList: any = [];

        this.setState({ spinning:true })
        let value: any = await {
            "email": userProfile.mail ? userProfile.mail.trim().toLowerCase():""
        }
        await AutherizeActionModel.getGetMenus(value).then((res: any) => {
            menuDataList = res;
            //console.log(res);
            this.setState({ spinning: false })
            //res == [] ? [] : res.map((a: any) => {})
        });

        await this.setState({ menuDataList })

    }
    onCollapseSider = (collapseSider: boolean) => {
        this.setState({ collapseSider })
        window.localStorage.setItem("collapseSider", JSON.stringify(collapseSider))

    }

    loginFormVisible = () => {
        this.setState({ loginVisible :true})

    }

    login = () => {

        this.setState({ loginVisible: false, isLogin: true })

    }

    logout = async () => {
        const { conf } = this.state;
        const app = await createPublicClientApplication(conf);
        const accessType:any  =  window.sessionStorage.getItem('accessType')
        if (accessType=='ad') {
            //await this.publicClientApplication.initialize();
            //await this.publicClientApplication.logoutPopup();
            await app.initialize();
            await app.logoutPopup();
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        } else if (accessType == 'b2c') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        }
    }

    //menuDashboardRoute = (path: any) => {
    //    window.location.href = path
    //}


    



    public render() {
        const { currentURL, collapseSider, isLogin, loginVisible, userProfile, menuDataList, spinning } = this.state;

        
        //const items: MenuItem[] = [
        //    getItem('Dashboard', 'hDashboard', <FundProjectionScreenOutlined />,
        //        [
        //            getItem(<NavLink tag={Link} className="" to="/Dashboard/VN" > Dashboard VN</NavLink >, '/Dashboard VN', null),
        //            getItem(<NavLink tag={Link} className="" to="/Dashboard/External" > Dashboard External</NavLink >, '/Dashboard External​', null)
        //        ]
        //    ),
        //    getItem('Upload Data', 'upload', <UploadOutlined />,
        //        [
        //            getItem(<NavLink tag={Link} className="" to="/Project" >Project</NavLink >, '/RegisterProject', null),

        //            getItem(<NavLink tag={Link} className="" to="/UploadFile" > Upload Data</NavLink >, '/UploadFile', null),
        //    ]),
        //];

        const itemsUser: MenuItem[] = [
            getItem(<><span>{userProfile?userProfile.displayName:''}</span> <Avatar size="default" icon={<UserOutlined />} /></>, "user", undefined,[

                //getItem(<a className="" > Your profile </a >, '/profile', undefined),
                getItem(<a className="" onClick={() => this.logout()} > Sign out</a >, '/signout', undefined),
            ]
            )
            ];

        const itemsLogin: MenuItem[] = [
            getItem(<a className="" onClick={() => this.loginFormVisible()}> Login </a >, '/Login', undefined),
        ];

        return (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: 'rgb(4, 153, 131)',
                        //borderRadius: data.borderRadius,
                        colorBgLayout: "#fff",

                        
                    },
                    components: {
                        Layout: {
                            lightTriggerBg: 'rgb(50, 49, 129)',
                            colorPrimary: 'rgb(4, 153, 131)',//เขียว SCGC
                            lightSiderBg: 'rgb(50, 49, 129)',
                            lightTriggerColor: '#fff',
                            headerBg: '#fff',
                        },
                        Menu: {
                            darkItemBg: 'rgb(50, 49, 129)',
                            dangerItemColor: 'rgb(4, 153, 131)',
                            darkSubMenuItemBg: 'rgb(50, 49, 129)',//น้ำเงิน SCGC
                        },
                    },
                }}
            >
                <Spin spinning={spinning} fullscreen />
                    <Layout style={{ minHeight: '100vh' }}>
                        <Sider
                            theme='light'
                            collapsible
                            collapsedWidth={"65px"}
                            style={{ overflow: "hidden" }}
                            onCollapse={(e: any) => this.onCollapseSider(e)}
                        defaultCollapsed={collapseSider}
                        width='250px'

                        >
                        <NavbarBrand tag={Link} to="/">
                            <div style={{ textAlign: "center", marginTop: '0px', marginLeft: collapseSider ?'0px':'30px' }} >
                                {!collapseSider ? <div>
                                    <img
                                        src={logoTHEARCH}
                                        width="170px"
                                    />
                                </div> :
                                    <div style={{ overflow: 'hidden', width: '64px', height: '90px' }}>
                                        <img
                                            src={logoTHEARCH}
                                            width="170px"
                                        />
                                    </div>
                                }
                                {!collapseSider ? <h3 style={{ color: "#fff", marginTop: '0px' }}>Spend Analysis</h3> : null}
                                </div>
                            </NavbarBrand>
                        {isLogin ? <MainMenu currentURL={currentURL} menuData={menuDataList} />
                                : null}
                        </Sider>
                        <Layout>
                            {isLogin ? <Header
                                style={{ padding: 0, border: '1px solid rgba(0, 0, 0, 0.05)' }}
                            >
                                {/*<div style={{ float: 'left', marginTop: '15px' }} >*/}
                                {/*    <h3 style={{ color: "rgb(50, 49, 129)" }}>Spend Analysis</h3>*/}
                                {/*</div>*/}
                                <div style={{ float: 'right' }}>

                                    {isLogin ?
                                        //<Dropdown menu={{ items: itemsUser }}>
                                        //    <Space style={{ marginRight: '10px' }}>
                                        //        <span>Angkrish Fuekaew</span>
                                        //        <Avatar size="default" icon={<UserOutlined />} />
                                        //        {/*<DownOutlined />*/}
                                        //    </Space>
                                        //</Dropdown>
                                        <Menu theme='light' mode="horizontal" items={itemsUser} />
                                        :
                                        <Menu theme='light' items={itemsLogin} />

                                    }
                                </div>

                            </Header>
                            : null}
                        <Content
                            style={{ padding: '15px' }}
                        >
                                {this.props.children}
                            </Content>

                        </Layout>
                    </Layout>
                   



                <Drawer
                    title={'Login'}
                    visible={loginVisible}
                    width={720}
                    closable={false}
                >

                    <Button
                        onClick={() => this.login()}
                    >
                        Login
                    </Button>
                    <Button
                        onClick={() => this.setState({ loginVisible :false})}
                    >
                        Cancel
                    </Button>
                </Drawer>


            </ConfigProvider>
        );
    }
}