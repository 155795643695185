import { CheckCircleFilled, CloudDownloadOutlined,  ExclamationCircleFilled,  FolderAddFilled,  FormOutlined,  PlusOutlined, PoweroffOutlined, StopFilled } from '@ant-design/icons';
import { Col, Menu, Row, Select, Spin, Switch, Table, TableColumnsType, message, Modal } from 'antd';
import * as React from 'react';
import ProjectActionModel from '../../_constant/Project/ProjectActionModel';
import RegisterTopicForm from './RegisterTopicForm';
import { format, } from 'date-fns';
const dateFormat = 'dd-MMM-yyyy HH:mm:ss';
import { getUserProfile } from '../../authen/UserProfile';

const { confirm } = Modal;
const success = (msn: any) => {
    message.success({
        type: 'success',
        content: msn,
    });
};
const error = (msn: any) => {
    message.error({
        type: 'error',
        content: msn,
    });
};
const warning = (msn: any) => {
    message.warning({
        type: 'warning',
        content: msn,
    });
};



class RegisterTopic extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visibleRegisterProjectForm: false,
            projectList: [],
            projectListTable: [],
            searchProject: [],
            rowTopicSelection: [],
            deleteButtonVisible: false,
            pagination: {},
            pageSize: 10,
            project: [],
            sheets: [],
            uploadList: [],
            selectedRowKey: 0,
            formType: "",
            sheetInfo: {},
            spinning: false,
            onConfirmUpdateModal: true,
        }
    }

    componentWillMount = () => {
        let { folder } = this.props.match.params;
        this.setState({ folderName: folder })
        this.GetProjectById(folder);

    }

    reloadTable = async () => {
        let { folder } = await this.props.match.params;
        await this.GetProjectById(folder)
        this.setState({ visibleRegisterProjectForm: false, formType:""})
    }

    GetProjectById = async (e: any) => {
        //await console.log(e)
        try {
            const value: any = await {
                "projectID": e,
            };
            let sheets: any = [];
            let uploadList: any = [];
            //let project: any = [];
            this.setState({ spinning: true })
            await ProjectActionModel.getProjectById(value).then((res: any) => {
                //console.log(res)

                res.sheets == null ? [] : res.sheets
                    .map((d: any) => sheets.push({
                        key: d.sheetID,
                        SheetID: d.sheetID,
                        ProjectID: d.projectID,
                        SheetName: d.sheetName,
                        IngestMode: d.ingestMode,
                        CreatedDate: d.createDate, //format(d.createDate, dateFormat),
                        UpdatedBy: d.updateDate, //format(d.updateDate, dateFormat),
                        data: d,
                        IsActive: d.isActive
                        //Download: <NavLink tag={Link} style={{ alignContent: 'center', padding:0 }} className="" to={"#"} >{<CloudDownloadOutlined style={{ fontSize: 24 }} />}</NavLink >,
                        //Active: <Switch defaultChecked  />,
                    }));
                
                

                res.uploads == null ? [] : res.uploads
                    .map((d: any) => uploadList.push({

                        key: d.uploadID,
                        UploadID: d.uploadID,
                        SheetID: d.sheetID,
                        ProjectID: d.projectID,
                        FileUploadName: d.fileUploadName,
                        FileUploadSheetName: d.fileUploadSheetName,

                    }));
                this.setState({ sheets, uploadList, project: res, spinning:false })
                //console.log(res)


            })
        } catch {
            this.setState({ sheets: [], uploadList: [], project: [], spinning: false })

        }

            window.scrollTo(0, 0)

    }

    onChangeSearch = (e: any) => {

        const { projectList } = this.state;


        let searchProject = e;
        try {

            let projectListTable: any = [];

            if (searchProject.length > 0) {

                searchProject.map((a: any) => {
                    projectList.filter((b: any) => JSON.stringify(b).toLowerCase().trim().includes(a.toLowerCase().trim()))
                        .map((c: any) =>
                            projectListTable.push(c)
                        )
                })


            } else {
                projectListTable = projectList;
            }



            this.setState({ projectListTable })


        }
        catch {

        }

    }

    onClickButtonBar = (e: any) => {
        const { rowTopicSelection, } = this.state

       
        switch (e.key) {
            case 'create':
                this.setState({ visibleRegisterProjectForm: true, formType: "create", sheetInfo: {}, selectedRowKey: null, rowTopicSelection:[] })
                break;
            case 'edit': this.setState({ visibleRegisterProjectForm: true, formType: "edit" })
                break;
            case 'download': window.location.href = rowTopicSelection.data.downloadTemplateUri //console.log('download',rowTopicSelection)
                break;
            case 'active': this.onChangeActive(rowTopicSelection)
                break;
        }
        //console.log(e)
    }

    onChangeActive = async (rowTopicSelection: any) => {
        let { folder } = await this.props.match.params;
        //const { onConfirmUpdateModal } = this.state;
        let userProfile: any = await getUserProfile();


        const value: any = await {
            "SheetID": rowTopicSelection.SheetID,
            "SheetName": rowTopicSelection.SheetName,
            "IsActive": !rowTopicSelection.IsActive,
            "CreatorName": userProfile.mail,
        }
        let text = await value.IsActive?"Active":"Inactive"

       const confirmUpdate = () => {
         ProjectActionModel.activedTopic(value).then((res: any) => {

                this.GetProjectById(folder);
             this.setState({ sheets: [], uploadList: [], project: [], spinning: false, selectedRowKey: null, rowTopicSelection: [] })
            })
           

        }
        //const hideModal = () => {
        //    this.setState({ onConfirmUpdateModal :false}) 
        //}

        await   confirm({
            title: 'Do you want to '+text+' these topic?',
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',

            okText: 'Cancel',
            okType: 'danger',
            cancelText: 'OK',
            onOk() {
            },
            onCancel() {
                confirmUpdate()
            },
            
        });

    }

    componentWillReceiveProps = (props: any) => {
        let { folder } = this.props.match.params;
        //console.log(folder,newfolder)
        if (props.match.params.folder !== folder) {
            this.setState({ folderName: props.match.params.folder })
            this.GetProjectById(props.match.params.folder);
        }
    }


   

    public render() {
        const { visibleRegisterProjectForm, project, sheets, selectedRowKey, rowTopicSelection, formType, sheetInfo, spinning } = this.state;
        //let { folder } = this.props.match.params;

        const handleChangePage = (page: any, pageSize: any) => {
            this.setState({ pageSize })
            //this.GetProjectsData({ page, pageSize })
        };

      

        const onClickDownload = (e: any) => {

            console.log(e)

        }

        const columnsSheet: TableColumnsType<any> = [
            {
                title: 'Topic ID',
                dataIndex: 'SheetID',
                width:'90px',
            },
            {
                title: 'Topic Name',
                dataIndex: 'SheetName',
            },
            {
                title: 'Created Date',
                dataIndex: 'CreatedDate',
                width: 250,
                render: (CreatedDate: any) => format(CreatedDate, dateFormat)

            },
            {
                title: 'Modified Date',
                dataIndex: 'UpdatedBy',
                width: 250,
                render: (UpdatedBy: any) => format(UpdatedBy, dateFormat)

            },
            //{
            //    title: 'Download',
            //    dataIndex: 'data',
            //    align: "center",
            //    width: '90px',

            //    render: (data: any) => <NavLink tag={Link} style={{ alignContent: 'center', padding: 0 }} className="" to={"#"} onClick={onClickDownload} > {< CloudDownloadOutlined style={{ fontSize: 24 }} />}</NavLink >
            //},
            //{
            //    title: 'Edit',
            //    dataIndex: 'data',
            //    align: "center",
            //    width: '90px',

            //render: (data: any) => <NavLink tag={Link} style={{ alignContent: 'center', padding: 0 }} className="" to={"#"} onClick={onClickDownload} > {<FormOutlined style={{ fontSize: 24 }} />}</NavLink >
            //},
            {
                title: 'Active',
                dataIndex: 'IsActive',
                align: "center",
                render: (IsActive: any) => IsActive ? <CheckCircleFilled style={{ color: "rgb(4, 153, 131)", }} /> : <StopFilled style={{ color: "rgb(191, 191, 191)", }} /> ,
                width: '90px',
            },
        ];
        const items: any = [
            {
                label: 'Create',
                key: 'create',
                icon: <PlusOutlined />,
            },
            {
                label: 'Edit',
                key: 'edit',
                icon: <FormOutlined />,
                disabled: rowTopicSelection.key == undefined, //disabled function
            },
            {
                label: 'Download',
                key: 'download',
                icon: <CloudDownloadOutlined />,
                disabled: rowTopicSelection.key == undefined,
            },
            {
                label: 'Active',
                key: 'active',
                icon: <PoweroffOutlined />,
                disabled: rowTopicSelection.key == undefined,
            },
        
        ];

        const onRow = (record: any,index:any) => {
            const { selectedRowKey } = this.state
            //console.log(index)
            return {
                onClick: () => {
                    if (selectedRowKey == record.key) {
                        
                        this.setState({ selectedRowKey: null, rowTopicSelection: [], sheetInfo: {} })

                    } else {
                        const value: any =  {
                            "SheetID": record.key,
                        };
                        this.setState({ spinning:true })  
                        ProjectActionModel.getSheetById(value).then((res: any) => {
                            //console.log(res)
                            this.setState({ selectedRowKey: record.key, rowTopicSelection: record, sheetInfo: res, spinning: false })

                        })

                    }
                  
                },
            };
        };

        const rowClassName = (record: any) => (record.key === selectedRowKey ? 'ant-table-row-selected ' : '');

        return (<>

          
            <Row style={{ padding: "10px" }}>
                <Spin spinning={spinning} fullscreen />
                <h4><FolderAddFilled style={{ color: "#FFD700", paddingRight: "10px" }} />Register Topic / {project.projectName}</h4>
                <Col
                    key="Search"
                    span={24} >
                    <Menu
                        selectedKeys={["0"]}
                        mode="horizontal"
                        items={items}
                        onClick={(e: any) => this.onClickButtonBar(e)}
                        defaultChecked
                        
                    />
                </Col>
            </Row>
            <Row style={{ padding:"5px" }}>
                <Col
                    key="Search"
                    span={6} >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Search"
                        showSearch
                        onChange={(e: any) => this.onChangeSearch(e)}
                        size={"small"}
                    />
                </Col>
            </Row>

            <Table
                columns={columnsSheet}
                dataSource={sheets}
                size="small"
                pagination={{ pageSize: 10 }}
                onRow={onRow}
                rowClassName={rowClassName}
                style={{ cursor: "pointer" }}
            />

            {/* Form Area */}
            <Modal
                visible={visibleRegisterProjectForm}
                footer={false}
                closable={false}
                //onCancel={() => this.setState({ visibleRegisterProjectForm: false })}
                width={'800px'}
            >
                <RegisterTopicForm
                    sheets={sheets}
                    project={project}
                    reloadTable={() => this.reloadTable}
                    closeForm={() => this.setState({ visibleRegisterProjectForm: false })}
                    formType={formType}
                    sheetItem={sheetInfo}

                />
            </Modal>
        </>
        );
    }
}

export default (RegisterTopic);