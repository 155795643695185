import {  DeleteOutlined, FileExcelOutlined, FolderOpenFilled, FolderOutlined, MailOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Alert, Col, Menu, Pagination, Row, Select, Spin, Table, TableColumnsType, Typography, } from 'antd';
import * as React from 'react';
import { Route } from 'react-router';
import { getUserProfile } from '../../authen/UserProfile';
import Dashboard from './Dashboard';
import AuthorizeActionModel from '../../_constant/Authorization/AuthorizeActionModel';

class DashboardRoute extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            authorizetion: null,
            path: "/dashboard/",
            spinning:false,
        }
    }
    componentWillMount() {
        this.GetAuthorizetion()

    }

    GetAuthorizetion =async () => {
        let userProfile: any = await getUserProfile();
        let { COMPANY } = await this.props.match.params;
        //let { url } = await this.props.match
        let { path } = await this.state;
        this.setState({ spinning: true })

        
        //console.log('url',url)

        let value: any = await {
            "Email": userProfile.mail.trim().toLowerCase(),
            "Path": path + COMPANY,
        };

        try { 

            await AuthorizeActionModel.getAuthorizePath(value).then((res: any) => {
                let _res = res
                this.setState({ spinning: false })
                this.setState({ authorizetion: _res.isAuthorize })
            })
            
        } catch {
            this.setState({ authorizetion: false })

        }

    }


    logout = async () => {

        const accessType: any = window.sessionStorage.getItem('accessType')
        if (accessType == 'ad') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        } else if (accessType == 'b2c') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        }
    }


    componentWillReceiveProps(props: any) {
        if (props !== this.props) {
            this.GetAuthorizetion();
        }
    }

    public render() {
        const { authorizetion, spinning } = this.state;
        return (<>
            <Spin spinning={spinning} fullscreen />
            {authorizetion==true ?
                <Route path='/dashboard/:COMPANY' component={Dashboard}/>
                : authorizetion == null ? null : <Route path='/dashboard/:COMPANY' component={Unauthorize} />}
        </>

        );
    }
}


class Unauthorize extends React.Component<any, any> {

    public render() {
        return (<>
            <div className="unauthorized-page">
                <Typography.Title level={2}>Unauthorized Access</Typography.Title>
                <Alert
                    type="error"
                    message="You are not authorized to access this page. Please log in or contact the administrator."
                />
            </div>

        </>)
    }
}



export default (DashboardRoute);