import { Breadcrumb, Menu, Layout, MenuProps, Avatar,ConfigProvider, Divider, Dropdown, Space, Drawer, Button } from 'antd';
import React from 'react';

const { Header, Content, Footer, Sider } = Layout;
import {
    TeamOutlined,
    FundProjectionScreenOutlined,
    UploadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    Link,
} from 'react-router-dom';
import { NavbarBrand, NavLink } from 'reactstrap';
type MenuItem = Required<MenuProps>['items'][number];
//import logo from '../SCGLogo/SCGC_WH_logo_3889x1963px.png';
//import 'antd/dist/antd.css'; 

function handleClick(e: any) {
    console.log(e.keyPath[0])
    window.location.href = e.keyPath[0]
}
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

export default class LoginLayout extends React.PureComponent<{}, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ...this.state,
            currentURL: "",
            collapseSider: false,
            isLogin: false,
            loginVisible:false,
        }
    }

    componentWillMount = () => {
        const { hash, pathname, search } = location;

        let collapseSiderStr = window.localStorage.getItem("collapseSider");
        let collapseSider = collapseSiderStr == null ? false : JSON.parse(collapseSiderStr)



        //console.log({ 'hash': hash, 'pathname': pathname, 'search': search })
        this.setState({ currentURL: pathname, collapseSider })
    }

    selectMenu = (e:any) => {

        //window.location.href = e.selectedKeys[0]
    }
    onCollapseSider = (collapseSider: boolean) => {
        this.setState({ collapseSider })
        window.localStorage.setItem("collapseSider", JSON.stringify(collapseSider))

    }

    loginFormVisible = () => {
        this.setState({ loginVisible :true})

    }

    login = () => {

        this.setState({ loginVisible: false, isLogin: true })

    }


   


    public render() {
        const {isLogin, loginVisible } = this.state;

        
        const items: MenuItem[] = [
            getItem(<NavLink tag={Link} className="" to="/Dashboard" > Dashboard</NavLink >, '/Dashboard', <FundProjectionScreenOutlined />),
            getItem(<NavLink tag={Link} className="" to="/counter" > Upload Data</NavLink >, '/counter', <UploadOutlined />),
            getItem(<NavLink tag={Link} className="" to="/fetch-data" > Setup Authorize</NavLink >, '/fetch-data', <TeamOutlined />),
        ];

        const itemsUser: MenuItem[] = [
            getItem(<><span>Angkrish Fuekaew</span> <Avatar size="default" icon={<UserOutlined />} /></>, "user", undefined,[

                getItem(<a className="" > Your profile </a >, '/profile', undefined),
                getItem(<a className="" onClick={() => this.setState({ isLogin: false })} > Sign out</a >, '/signout', undefined),
            ])];

        const itemsLogin: MenuItem[] = [
            getItem(<a className="" onClick={() => this.loginFormVisible()}> Login </a >, '/Login', undefined),
        ];

        return (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: 'rgb(4, 153, 131)',
                        //borderRadius: data.borderRadius,
                        colorBgLayout: "#fff",
                        

                        
                    },
                    components: {
                        Layout: {
                            lightTriggerBg: 'rgb(50, 49, 129)',
                            colorPrimary: 'rgb(4, 153, 131)',
                            lightSiderBg: 'rgb(50, 49, 129)',
                            lightTriggerColor: '#fff',
                            headerBg: '#fff',
                        },
                        Menu: {
                            darkItemBg: 'rgb(50, 49, 129)',
                            dangerItemColor: 'rgb(4, 153, 131)',
                        },
                    },
                }}
            >


                <Layout style={{ minHeight: '100vh', }}>
                 
                    <Layout
                        //style={{ backgroundImage: "url('data:image/png;base64,'" + imageBase64 +')' }}
                    >
                        <Header hidden
                            style={{ padding: 0, border: '1px solid rgba(0, 0, 0, 0.05)' }}
                            >
                            {/*<div style={{ float: 'left', marginTop: '15px' }} >*/}
                            {/*    <h3 style={{ color: "rgb(50, 49, 129)" }}>Spend Analysis</h3>*/}
                            {/*</div>*/}
                            <div style={{ float: 'right' }}>

                                {isLogin ?
                                    //<Dropdown menu={{ items: itemsUser }}>
                                    //    <Space style={{ marginRight: '10px' }}>
                                    //        <span>Angkrish Fuekaew</span>
                                    //        <Avatar size="default" icon={<UserOutlined />} />
                                    //        {/*<DownOutlined />*/}
                                    //    </Space>
                                    //</Dropdown>
                                    <Menu theme='light' mode="horizontal" items={itemsUser} />
                                    :<Menu theme='light' items={itemsLogin}/>
                                   
                                    }
                              </div>

                            </Header>
                        <Content style={{ margin: '0 16px' }}>
                            {this.props.children}
                        </Content>

                    </Layout>
                </Layout>





                <Drawer
                    title={'Login'}
                    visible={loginVisible}
                    width={720}
                    closable={false}
                >

                    <Button
                        onClick={() => this.login()}
                    >
                        Login
                    </Button>
                    <Button
                        onClick={() => this.setState({ loginVisible :false})}
                    >
                        Cancel
                    </Button>
                </Drawer>


            </ConfigProvider>
        );
    }
}









