import * as React from 'react';
import { connect } from 'react-redux';
import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser'
import { Button, Col, Row, Spin } from 'antd';
import { useHistory, } from 'react-router-dom';
import './Login.css'
import logoSCG from '../../SCGLogo/SCGC_4C_logo_3889x1963px.png';
import logoTHEARCH from '../../SCGLogo/THEARCH.png';
import AutherizeActionModel from '../../_constant/Authorization/AuthorizeActionModel';

interface DecodedAccessToken {
    exp: number;
}

import * as Authentication from '../../store/Authentication';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';


 function createPublicClientApplication(config: any) {
    //console.log(config)

    return   new PublicClientApplication({
        auth: {
            clientId: config.clientId,
            redirectUri: config.redirectUri,
            authority: config.authority,
        },
        cache: {
            cacheLocation:
                BrowserCacheLocation.SessionStorage,
            storeAuthStateInCookie: true,
        },
    });
}


class Login extends React.PureComponent<any, any>{
    //publicClientApplication: PublicClientApplication;

    constructor(props: any) {
        super(props);
        this.state = {
            ...this.state,
            error: null,
            isAuthenticated: false,
            user: {},
            hoverColorAD: "url(#twoToneGradient2)",
            hoverColorB2C: "url(#twoToneGradient2)",
            conf: {},
            spinning: false,
        }

        this.login = this.login.bind(this);
        //this.publicClientApplication = new PublicClientApplication({
        //    auth: {
        //        clientId: config.clientId,
        //        redirectUri: config.redirectUri,
        //        authority: config.authority,
        //    },
        //    cache: {
        //        cacheLocation: BrowserCacheLocation.SessionStorage,
        //        storeAuthStateInCookie: true,

        //    }
        //})
    }
    componentDidMount = () => {
        this.setState({ spinning:true })

        AutherizeActionModel.getLoginConfig().then((res: any) => {

            //console.log(res)
            this.setState({ conf: res, spinning: false })
        })
    }

    componentWillMount = () => {
        //await this.publicClientApplication.initialize();
        //this.props.requestAuthentication()
        //this.props.requestAuthentication();
       

        const regex = /id_token=([^#]*)/;
        const url = this.props.location.hash;
        const match = url.match(regex);
        const idToken = match ? match[1] : '';

        
        //console.log(process.env); // Output: 12345

        if (idToken) {
            window.sessionStorage.setItem('accessToken', idToken)
            window.sessionStorage.setItem('accessType', 'b2c')
            window.location.href = '/'
        }

        

        //console.log(match)


        //this.props.p

        //const location = useLocation();
        //const userId = new URLSearchParams(location.search) //.get('userId');

    }
    loginB2C = () => {
        const { conf } = this.state;
        window.location.href = conf.b2CAuthen;
    }
    login = async () => {
        const { conf } = await this.state;
        try {

            //await this.publicClientApplication.initialize();
            //await this.publicClientApplication.loginPopup({
            //    scopes: config.scopes,
            //    prompt: "select_account"

            //}).then(res =>
            //{
            //    window.sessionStorage.setItem('accessToken', res.accessToken)
            //    window.sessionStorage.setItem('accessType', 'ad')
            //}
            //)
            const app = await createPublicClientApplication(conf);

            await app.initialize();
            await app.loginPopup({
                scopes: conf.scopes,
                prompt: "select_account"

            }).then(res =>
            {
                window.sessionStorage.setItem('accessToken', res.accessToken)
                window.sessionStorage.setItem('accessType', 'ad')
            }
            )

            window.location.href = '/'
            this.setState({ isAuthenticated: true })
        } catch (err) {

            this.setState({
                isAuthenticated: false,
                user: {},
                error: err
            })
            const history: any = useHistory();

            console.log('error:', err)
            //window.sessionStorage.removeItem('accessToken')

        }

    }

    logout = async () => {
        const { conf } = this.state;
        const app = createPublicClientApplication(conf);
        await app.initialize();
        await app.logoutPopup();
        await window.sessionStorage.removeItem('accessToken')
        window.location.href = '/'
    }


   

    handleMouseEnterAD = () => {
        this.setState({ hoverColorAD: "url(#twoToneGradient)" })  // Change to hover color (blue)
    };

    handleMouseLeaveAD = () => {
        this.setState({
            hoverColorAD: "url(#twoToneGradient2)"
        }); // Change back to original color
    };

    handleMouseEnterB2C = () => {
        this.setState({ hoverColorB2C: "url(#twoToneGradient)" })  // Change to hover color (blue)
    };

    handleMouseLeaveB2C = () => {
        this.setState({
            hoverColorB2C: "url(#twoToneGradient2)"
        }); // Change back to original color
    };

    public render() {

        const { hoverColorAD, hoverColorB2C, conf, spinning } = this.state;

        return (
            <>
                {
                    //<button onClick={() => this.login()}> Login </button>
                    //<button onClick={() => this.logout()}> Logout </button>
                    //<button onClick={() => this.getdata()}> getdata </button>
                }


                <div className="login-container">
                    <Spin spinning={spinning} fullscreen />


                        <Row>
                            <Col offset={20}>
                                <img
                                    src={logoSCG}
                                    width="150vh"
                                    style={{ float: 'right', position: 'relative',top:50 }}
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <img
                                    src={logoTHEARCH}
                                    width="380vh"
                                />
                            </Col>
                        </Row>
                        <h2 style={{ color: 'rgb(50, 49, 129)' }}> <span style={{ color: 'rgb(4, 153, 131)' }}>S</span> p e n d  <span style={{ color: 'rgb(4, 153, 131)' }}>A</span> n a l y s i s </h2>
                        <div className="login-buttons" style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ padding: '10px' }}>
                                <svg width="200" height="173.2" viewBox="0 0 200 173.2" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    cursor="pointer"
                                    onClick={() => this.login()}
                                >
                                    <defs>
                                        <linearGradient id="twoToneGradient" gradientUnits="objectBoundingBox">
                                            <stop offset="0%" stop-color="rgb(50, 49, 129)" />
                                            <stop offset="100%" stop-color='rgb(4, 153, 131)' />
                                        </linearGradient>
                                    </defs>
                                    <defs>
                                        <linearGradient id="twoToneGradient2" gradientUnits="objectBoundingBox">
                                            <stop offset="0%" stop-color="rgb(4, 153, 131)" />
                                            <stop offset="100%" stop-color='rgb(50, 49, 129)' />
                                        </linearGradient>
                                    </defs>
                                    <path d="M50 0L150 0L200 86.6L150 173.2L50 173.2L0 86.6Z"
                                        fill={hoverColorAD}
                                        onMouseEnter={this.handleMouseEnterAD}
                                        onMouseLeave={this.handleMouseLeaveAD}
                                    />
                                 <text x="100" y="90" fill="#fff" font-size="28" font-weight="bold" text-anchor="middle"
                                        onMouseEnter={this.handleMouseEnterAD}
                                        onMouseLeave={this.handleMouseLeaveAD}>SCGC </text>
                                    {/*<text x="100" y="100" fill="#fff" font-size="28" font-weight="bold" text-anchor="middle"*/}
                                    {/*    onMouseEnter={this.handleMouseEnterAD}*/}
                                    {/*    onMouseLeave={this.handleMouseLeaveAD}*/}
                                    {/*> Account</text>*/}
                                    <text x="100" y="110" fill="#fff" font-size="16" font-weight="bold" text-anchor="middle"
                                        onMouseEnter={this.handleMouseEnterAD}
                                        onMouseLeave={this.handleMouseLeaveAD}
                                    >(@SCG.com)</text>
                                </svg>
                            </span>
                            <span style={{ padding: '10px' }}>

                                <svg width="200" height="173.2" viewBox="0 0 200 173.2" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    cursor="pointer"
                                    onClick={() => this.loginB2C()}
                                >
                                    <defs>
                                        <linearGradient id="twoToneGradient" gradientUnits="objectBoundingBox">
                                            <stop offset="0%" stop-color="rgb(50, 49, 129)" />
                                            <stop offset="100%" stop-color='rgb(4, 153, 131)' />
                                        </linearGradient>
                                    </defs>
                                    <defs>
                                        <linearGradient id="twoToneGradient2" gradientUnits="objectBoundingBox">
                                            <stop offset="0%" stop-color="rgb(4, 153, 131)" />
                                            <stop offset="100%" stop-color='rgb(50, 49, 129)' />
                                        </linearGradient>
                                    </defs>
                                    <path d="M50 0L150 0L200 86.6L150 173.2L50 173.2L0 86.6Z"
                                        fill={hoverColorB2C}
                                        onMouseEnter={this.handleMouseEnterB2C}
                                        onMouseLeave={this.handleMouseLeaveB2C}
                                    />
                                    <text x="100" y="95" fill="#fff" font-size="28" font-weight="bold" text-anchor="middle"
                                        onMouseEnter={this.handleMouseEnterB2C}
                                        onMouseLeave={this.handleMouseLeaveB2C}>External</text>
                                    {/*<text x="100" y="110" fill="#fff" font-size="28" font-weight="bold" text-anchor="middle"*/}
                                    {/*    onMouseEnter={this.handleMouseEnterB2C}*/}
                                    {/*    onMouseLeave={this.handleMouseLeaveB2C}*/}
                                    {/*> Account</text>*/}
                                </svg>
                            </span>
                        </div>
                    <Button type="link"
                        style={{ float: 'right', marginLeft: '220px' }}
                        href={conf.b2CForgotPassword}
                        >
                        Forgot Password (External User)
                        </Button>




                </div>



            </>
        )
    }



}

//export default connect()(Login);
export default connect(
    (state: ApplicationState) => state.authentication, // Selects which state properties are merged into the component's props
    Authentication.actionCreators // Selects which action creators are merged into the component's props
)(Login as any); // eslint-disable-line @typescript-eslint/no-explicit-any