import * as React from 'react';
import { connect } from 'react-redux';
import { PowerBIEmbed } from 'powerbi-client-react';
import './Dashboard.css';
import { models } from 'powerbi-client';
import ActionModel from '../../_constant/Embed/ActionModel'

class Dashboard extends React.PureComponent<any, any>{
	constructor(props: any) {
		super(props);
		this.state = {
			...this.state,
			currentURL: "",
			collapseSider: false,
			heightDiv: 600,
			getembedinfo: [],
		}
	}

	componentWillMount = () => {
		let { COMPANY } = this.props.match.params;
		ActionModel.getembedinfo(COMPANY).then(
			(res: any) => {
				this.setState({
					getembedinfo: {
						reportId: res.embedReport[0].reportId,
						embedUrl: res.embedReport[0].embedUrl,
						embedToken: res.embedToken.token
					}
				})
			}
		)

	}
	componentWillReceiveProps(props:any) {
		let { COMPANY } = props.match.params;
		ActionModel.getembedinfo(COMPANY).then(
			(res: any) => {
				this.setState({
					getembedinfo: {
						reportId: res.EmbedReport[0].ReportId,
						embedUrl: res.EmbedReport[0].EmbedUrl,
						embedToken: res.EmbedToken.Token

					}
				})
			}
		)

	}

	embed = () => {
	}

	public render() {
		const {
			 getembedinfo } = this.state;

		

		let reportId = getembedinfo.reportId;
		let embedUrl = getembedinfo.embedUrl;// "https://app.powerbi.com/reportEmbed?reportId=2e274213-9de8-442d-a968-1e541d4bf271&groupId=1fc6f33a-b94a-4e1b-bf39-023fbcbf12d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUVBU1QtQVNJQS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d"
		let embedToken = getembedinfo.embedToken;// "H4sIAAAAAAAEAB2TRa6FBgAA7_K3NMGtSRe4uzxgh7s8HJrevb_dz2om8_ePnT7DnBY_f_5cMgEX9jN5TpP3RH-gDF2YMK7tttOolJMTw4j6pcueuEFCVDjQh5Qrpw6i8PIoLHXSswKd6SxGT0rRKDnUsR7MeirepNbcD37YvL1idK_foucWDBq0Xw53DIT7LMB8wzRwGZMSgJzW6kP4vnbYTIRHIDjMJb7mCmWqei0WxWrExRVsC6iwOdkMJ1-ZJjJm2MaYJvvL1_vQA5VRpPogbjScV8AizkUnDLkIpiDnK1H6YjrnHiejYNLBeLtTQRQZoSVVbxpfCWmSw4H3Sh8Qte3k5pKmkOsZGVYY3DB8ZJIfIhvQEOBk91kH83ZLbFhiOkJ6WZboHalb24pY3JbrADtDtSOP2OM7thmYWWe-5Vkw8MV-bpmU1s6pmPHKHzbGQKalnNPHZIje-I3ra6dRgglGufYW4RC5-YMwrx5BVAozzesJuliU9WjL0eaFBMJmRrpsGG3SNKJyrezVY0I4yC_2WlKG-uk-8QIWLrr0qDVb1F4lRH6ZJxBIP_UQdCwIfW3tzGEijVRTLdgs4FV3w7o76RF4_-ZyKddoPTVUdUPax1pu79nCAYMQpQC3jDNURsDNSqnaY_RcihqBHBZGnrNJ2sWaAilxHgHjMxlHZCdcL_bLs_syjwLEXqEo4NL5aRWNgYtuiNxQG8BxwgflSrTOzbizghlqHEjmeH5ZbSOFQBizBB6x62X1QQHIC1NB1zZATOe23sfchPhxRVPSj-Hir_0lE1HcTtVaQxMDqNNnPuUSvyXcWwerSc_FRjULGMC-YJHsUas37_q2086QHfZz7k6aqt_uURFY5a9rUQILKKgYGPJ-MIgqK9kkkglr02IqlX_--OHWZ9lnrXx-d0rqvXU3dvD2ItV8iME5tde_-liqA5R2NG7mmpnGHpHK39zX1vLGhhojjqseBmwkx1Lk8bNWYgEIZXCP3Vw21ezmZbPqmQabMjESLxL97YSvlnqjZ_3idHBlYVy2uFVzX0NizCNvl2w27LfV45JeaLbMtFSMvHdTgtqFU_-QoVcIqDYhB_LD-SRA25IMEjo3OylG9sXE-kZZutnxMlkp6RV8e3WAL7PRxQhrZvRg75BTH5o4qUJFR13RendNDGH8rdqgorXMV-Ae89QTg5FlUzimLcJ-KU_BE0HJy8R6OGJf32ApexNJIB-qSir-wpKAlNxW6IiU-wwVJvyuARucgWtK_ddf_2l-lqZclfDXsmMXQXSTs8qhjVninKFEG8X8T3ltPaX7sZa_WFUiAk8jp5-EljvuE71Li3SBHd84sYRh_g53nus_ExqtBXlq4yfCq4g8stQbQh9bgEGxLM8qetND5zoQiLt6g4SrrKIAIR2yMBr0pM7WIhqhnPmNSEpI1dMJQL1U0kM-fSECVt6qUW18tpYwLS3rc--L7mUgAx7PJnv9egwTsO4-kzPIFZymFNq7wVhEoHZzmUOGj8Ni2g6EeJyVtaJgmkWPYjN3uFnHR3dgbm7MgkfXWGe0I4Kh7eTKfF-q3-ya8Al5fReAGUhOlAk1UQ-WA7zpe_ZFu2nip8tqVdmc2TGV39k-O_JS8NozRYlTbtnFBxwRY-KIvqk4BuiBAdABSUs7v5r_-ReKKE0bQgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUVBU1QtQVNJQS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImV4cCI6MTcxNjk1OTM5NiwiYWxsb3dBY2Nlc3NPdmVyUHVibGljSW50ZXJuZXQiOnRydWV9"


        return (
		<div style={{ height: '100vh' }}>
				<PowerBIEmbed
					cssClassName={"bi-embedded"}
					embedConfig={{
						type: 'report',   // ����ö�� 'dashboard', 'tile', ��� ��
						id: reportId,
						embedUrl: embedUrl,
						accessToken: embedToken,
						tokenType: models.TokenType.Embed,
						settings: {
							panes: {
								filters: {
									visible: false,
									expanded: false,
								},
							},
							background: models.BackgroundType.Transparent,
						}
					}}
					getEmbeddedComponent={(embeddedReport) => {
						//console.log('Embedded Report:', embeddedReport);
					}}
				/>


   </div>
        );
    }
};
export default connect()(Dashboard);

