import { Alert, Typography, } from 'antd';
import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { getUserProfile } from '../../authen/UserProfile';
import ProjectTopic from './ProjectTopic';
import RegisterTopic from './RegisterTopic';
import AuthorizeActionModel from '../../_constant/Authorization/AuthorizeActionModel';

class RegisterTopicRoute extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            authorizetion: null,
            path:"/ProjectTopic",
        }
    }
    componentWillMount() {
        //let { folder } = this.props.match.params;
        //console.log(folder,this.props.match.params)
        this.GetAuthorizetion()

    }

    GetAuthorizetion =async () => {
        let userProfile: any = await getUserProfile();
        let { url } = await this.props.match.params;
        let { path } = await this.state;
        //console.log(this.props)
        let value: any = await {
            "Email": userProfile.mail.trim().toLowerCase(),
            "Path": path,
        };

        try { 
       /* if (userProfile !== null && userProfile.mail.trim().toLowerCase() === 'ebiz_vis03@scg.com') {*/

            await AuthorizeActionModel.getAuthorizePath(value).then((res: any) => {
                let _res = res
                //console.log(res)
                this.setState({ authorizetion: _res.isAuthorize })
            })
            //get API

           

        //} else {
        //    this.setState({ authorizetion: false })

        //    }
        } catch {
            this.setState({ authorizetion: false })

        }

    }


    logout = async () => {

        const accessType: any = window.sessionStorage.getItem('accessType')
        if (accessType == 'ad') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        } else if (accessType == 'b2c') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        }
    }


    componentWillReceiveProps(props: any) {
        if (props !== this.props) {
            this.GetAuthorizetion();
        }
    }


    

    public render() {
        const { authorizetion } = this.state;
        return (
            <>
                {authorizetion == true ?
                    <Route path='/ProjectTopic' component={ProjectTopic} />
                    : authorizetion == null ? null : <Route path='/ProjectTopic' component={Unauthorize} />}
                {authorizetion == true ?
                    <Route path='/RegisterTopic/:folder' component={RegisterTopic} />
                    : authorizetion == null ? null : <Route path='/RegisterTopic/:folder' component={Unauthorize} />}
            </>
        );
    }
}


class Unauthorize extends React.Component<any, any> {

    public render() {
        return (<>
            <div className="unauthorized-page">
                <Typography.Title level={2}>Unauthorized Access</Typography.Title>
                <Alert
                    type="error"
                    message="You are not authorized to access this page. Please log in or contact the administrator."
                />
            </div>

        </>)
    }
}



export default (RegisterTopicRoute);