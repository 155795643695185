import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticationState {
    isLoading: boolean;
    //startDateIndex?: number;
    authentication: any;
}

//export interface Authentication {
//    date: string;
//    temperatureC: number;
//    temperatureF: number;
//    summary: string;
//}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAuthenticationAction {
    type: 'REQUEST_AUTHENTICATION';
    //startDateIndex: number;
}

interface ReceiveAuthenticationAction {
    type: 'RECEIVE_AUTHENTICATION';
    //startDateIndex: number;
    authentication: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAuthenticationAction | ReceiveAuthenticationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAuthentication: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
            fetch(`appauthen`)
                .then(response => response.json() as Promise<any>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_AUTHENTICATION', authentication: data });
                });

            dispatch({ type: 'REQUEST_AUTHENTICATION'});
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthenticationState = { authentication: [], isLoading: false };

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState | undefined, incomingAction: Action): AuthenticationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUTHENTICATION':
            return {
                //startDateIndex: action.startDateIndex,
                authentication: state.authentication,
                isLoading: true
            };
        case 'RECEIVE_AUTHENTICATION':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            //if (action.startDateIndex === state.startDateIndex) {
                return {
                    //startDateIndex: action.startDateIndex,
                    authentication: action.authentication,
                    isLoading: false
                };
            //}
        break;
    }

    return state;
};
