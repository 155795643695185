import {  DeleteOutlined, FileExcelOutlined, FolderOpenFilled, FolderOutlined, MailOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Alert, Col, Menu, Pagination, Row, Select, Spin, Table, TableColumnsType, Typography, } from 'antd';
import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { getUserProfile } from '../../authen/UserProfile';
import UploadBlob from './UploadBlob';
import AuthorizeActionModel from '../../_constant/Authorization/AuthorizeActionModel';

class UploadBlobRoute extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            authorizetion: null,
            path: "/UploadFile/",
            spinning:false,
        }
    }
    componentWillMount() {
        //let { folder } = this.props.match.params;
        //console.log(folder,this.props.match.params)
        this.GetAuthorizetion()

    }



    GetAuthorizetion =async () => {
        let userProfile: any = await getUserProfile();
        let { folder } = await this.props.match.params;
        let { path } = await this.state;
        //console.log(this.props)
            this.setState({ spinning: true })
        let value: any = await {
            "Email": userProfile.mail.trim().toLowerCase(),
            "Path": path + folder,
        };

        try { 
            //get API
            await AuthorizeActionModel.getAuthorizePath(value).then((res: any) => {
                let _res = res
                this.setState({ spinning: false })
                this.setState({ authorizetion: _res.isAuthorize })
            })

           

        } catch {
            this.setState({ authorizetion: false })

        }

    }


    logout = async () => {

        const accessType: any = window.sessionStorage.getItem('accessType')
        if (accessType == 'ad') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        } else if (accessType == 'b2c') {
            await window.sessionStorage.removeItem('accessToken')
            await window.sessionStorage.removeItem('accessType')
            window.location.href = '/'
        }
    }


    componentWillReceiveProps(props: any) {
        if (props !== this.props) {
            this.GetAuthorizetion();
        }
    }

    public render() {
        const { authorizetion, spinning } = this.state;
        return (<>
            <Spin spinning={spinning} fullscreen />
            {authorizetion==true ?
                <Route path='/UploadFile/:folder' component={UploadBlob}/>
                : authorizetion==null?null: <Route path='/UploadFile/:folder' component={Unauthorize} />}
        </>
        );
    }
}


class Unauthorize extends React.Component<any, any> {

    public render() {
        return (<>
            <div className="unauthorized-page">
                <Typography.Title level={2}>Unauthorized Access</Typography.Title>
                <Alert
                    type="error"
                    message="You are not authorized to access this page. Please log in or contact the administrator."
                />
            </div>

        </>)
    }
}



export default (UploadBlobRoute);