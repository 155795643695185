import * as React from 'react';
import { Form, Select, Button, Divider, message, UploadProps, TableColumnsType, Table, Alert, Modal,  } from 'antd';
import { ExclamationCircleFilled, ExclamationCircleOutlined, InboxOutlined, } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { getUserProfile } from '../../authen/UserProfile';
//import { format } from 'date-fns';

//function getDifference(array1: number[], array2: number[]): number[] {
//    const set1 = new Set(array1);
//    const difference: number[] = [];

//    for (const item of array2) {
//        if (!set1.has(item)) {
//            difference.push(item);
//        }
//    }

//    return difference;
//}

class UploadBlobForm extends React.Component<any, any> {
    formRef = React.createRef();
    constructor(props: any) {
        super(props);
        this.state = {
            fileList: [],
            selectedFile: [],
            sheetFileList:[],
            fileTempName: "",
            fileName: "",
            columnDetail: [],
            errorMsg: "",
            isSeeMoreErrorDetail: false,
            loading: false,
        }
    }

    success = (msn: any,) => {
        Modal.success({
            title: 'Success',
            content: msn,
        });
    };
    error = (title: any, msn: any) => {
        Modal.error({
            title: title,
            content: msn,
        });
    };
    warning = (title:any,msn: any) => {
        Modal.warning({
            title: title,
            content: msn,
        });
    };

    componentWillMount = () => {
        //let formRef: any = this.formRef;

        //console.log()
    }


    handleSubmit = async (e: any) => {
        let userProfile: any = await getUserProfile();
        const { project, reloadTable, sheets, } = this.props;
        const { fileTempName, fileName } = this.state;
        const sheetName = sheets.find((a: any) => a.SheetID == e.Sheet).SheetName
        //const myDate = new Date(); // Or get your date from elsewhere
        //const formattedDate = format(myDate, 'yyyy-MM ');
        let _response = false;
        let _data: any = [];

        //block submit button
        this.setState({loading:true })

        let formRef: any = this.formRef;
        try {
            if (sheetName != undefined) {
                let blobName: any = fileName;
                const value: any = await {
                    "FileName": fileTempName,
                    "BlobName": blobName,
                    "Sheet": JSON.stringify(e.Sheet),
                    "ContainerName": project.landingPath + "/" + project.projectName + "/" + sheetName + "/01_upload",
                    "ProjectID": JSON.stringify(project.projectID),
                    "CreatorName": userProfile.mail,
                    "SheetFile": e.sheetFile,
                };

              await fetch('/api/ProjectDataService/upload', {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', "Content-Type": "application/json" },
                    body: JSON.stringify(value),
                }).then(response => {
                   
                    _response = response.ok
                    return response.json();
                }).then(data => {
                    _data = JSON.parse(data.message);
                })
                .catch(error => {
                       console.error('There has been a problem with your fetch operation:', error);
                   });
                if (!_response) {
                    try {
                        const ctx = _data.detail.ctx;
                        const msg = _data.detail.msg;

                        const cols: any = [];
                        let _maxLength: number = Math.max(_data.detail.ctx.template_cols.length, _data.detail.ctx.upload_cols.length)

                        const combinedSet: any = new Set([..._data.detail.ctx.template_cols, ..._data.detail.ctx.upload_cols]);
                        const combinedArray: any[] = [...combinedSet];
                        combinedArray.map((a: any, i: any) => {
                            cols.push({
                                key: i,
                                template_col: _data.detail.ctx.template_cols.find((b: any) => b == a),
                                upload_col: _data.detail.ctx.upload_cols.find((b: any) => b == a)
                            })
                        })
                        this.setState({ columnDetail: cols, errorMsg: msg, loading: false, })
                    } catch {
                        this.warning('The uploaded file is invalid.','Please check the file and ensure it matches the template format.')
                        this.resetForm();
                        //reloadTable(project.projectID)
                    }

                } else {
                    //console.log(response.ok)
                    formRef.current.resetFields()
                    /* this.setState({ columnDetail: [], errorMsg: "", isSeeMoreErrorDetail: false })*/
                    this.resetForm();
                    this.success('Upload successful')

                }

                //if (!response.ok) {
                //    this.warning('Upload failed')
                //    throw new Error('Upload failed');

                //} else {
                //    console.log(response.ok)
                //    await formRef.current.resetFields()
                //    await reloadTable(project.projectID)
                //    this.success('Upload successful')
                //}

                //const data = await response.json();
                //console.log('Upload successful:', data);
            } else {

                this.warning('The uploaded file is invalid.', 'Please check the file and ensure it matches the template format.')

            }
        } catch (error) {
            this.error('Upload error:', error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };


    resetForm = () => {
        const { closeForm, reloadTable, project } = this.props;
        let formRef: any = this.formRef;
        formRef.current.resetFields()
        this.setState({ sheetColumns: [], columnDetail: [], errorMsg: "", isSeeMoreErrorDetail: false, loading:false })
        //this.setState({ columnDetail: [], errorMsg: "", isSeeMoreErrorDetail: false })
        reloadTable(project.projectID)
        closeForm()
    }

    public render() {
        const { sheets, } = this.props;
        const { sheetFileList, columnDetail, errorMsg, isSeeMoreErrorDetail, loading } = this.state;
        let formRef:any = this.formRef;

        //const [form] = Form.useForm();
        const props: UploadProps = {
            name: 'file',
            maxCount: 1,
            //method: 'POST',
            //action: '/api/ProjectDataService/checkuploadfile',
            action: ((file: File) => '/api/ProjectDataService/checkuploadfile'),
            onChange(info:any) {
               onChangeUplaod(info);
            }
        }
        const onChangeUplaod = async (info: any) => {

            //await console.log(info.file.response)
            if (info.file.response != undefined) {
                let sheetFileList: any = await [];
                let fileTempName: any = await "";
                let fileName: any = await "";
                await info.file.response.sheetNames == undefined ? [] :
                    info.file.response.sheetNames.map((d: any) => {
                        sheetFileList.push({
                            key: d,
                            value: d,
                            label: d,
                        })
                    });

                fileTempName = await info.file.response.fileTempName == ""
                    || info.file.response.fileTempName == null
                    || info.file.response.fileTempName == undefined ? "" :
                    info.file.response.fileTempName;

                fileName = await info.file.response.fileName == ""
                    || info.file.response.fileName == null
                    || info.file.response.fileName == undefined ? "" :
                    info.file.response.fileName;
                //await console.log("fileTempName", fileTempName)
                await this.setState({ columnDetail: [], errorMsg: "", isSeeMoreErrorDetail: false })
                await formRef.current.setFieldsValue({sheetFile:""})
                await this.setState({ sheetFileList, fileTempName,fileName })
            }
        }

        const columnsCols: TableColumnsType<any> = [
            {
                title: 'No.',
                dataIndex: 'key',
                width: '90px',
                render:(key:any)=> key+1
            },
            {
                title: 'Template Column',
                dataIndex: 'template_col',
                width: '300px',
                render: (template_col: any) => template_col ? template_col : <span style={{ color: "rgb(237, 28, 36)" }}>Not match</span> 


            },
            {
                title: 'Upload Column',
                dataIndex: 'upload_col',
                width: '300px',
                render: (upload_col: any) => upload_col ? upload_col : <span style={{ color: "rgb(237, 28, 36)" }}>Not match</span> 
            },
        ];

        return (<>
            <Divider orientation="left"> Register Project</Divider>
            <Form ref={formRef}  layout="vertical" onFinish={this.handleSubmit}>
                <Form.Item label="Topic" name="Sheet" rules={[{ required: true, message: 'Please select!' }]}>
                    <Select 
                        options={sheets}
                        placeholder={"Please select sheet!'" }
                    />
                </Form.Item>

                <Form.Item label="Choose file" name="File" rules={[{ required: true, message: 'Please choose file!' }]}>
                    <Dragger{...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            The system only supports <span style={{ color: "#f00" }} >.xlsx</span> files.

                        </p>
                     
                    </Dragger>

                </Form.Item>

                <Form.Item label="Sheet name in file" name="sheetFile" rules={[{ required: true, message: 'Please select!' }]}>
                    <Select
                        options={sheetFileList}
                        placeholder={"Please select file sheet!'"}
                    />
                </Form.Item>

                <div style={{ float: 'right',}}>
                    {/*<Button type="primary" onClick={handleUpload} >Upload</Button>*/}
                    <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                    &nbsp;
                    <Button type="default" onClick={this.resetForm}>Cancel</Button>
                </div>

                

            </Form>
            <div className="clearfix" style={{ paddingBottom: '20px' }} />

            {columnDetail.length > 0 ? <>
                <span style={{ paddingTop: '20px' }}>{

                    <Alert
                        message="Error"
                        description={isSeeMoreErrorDetail ? errorMsg : <> {errorMsg.substring(0, 300)}... <Button type="text" onClick={() => this.setState({ isSeeMoreErrorDetail: true })}>See more.</Button></>}
                        type="error"
                        showIcon
                    />

                }</span>
                <Table
                    style={{ paddingTop: '20px' }}
                    columns={columnsCols}
                    dataSource={columnDetail}
                    size="small"
                    pagination={false}
                //pagination={{ pageSize: 10, pageSizeOptions: [10, 50, 100] }}
                // sortDirections={[]}
                /></> : null}
            <div className="clearfix" />
        </>
        );
    }
}

export default (UploadBlobForm);